import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import AdminService from '../../services/api';
import authService from '../../services/authService.js';
import MyButton from '../../components/MyButton';
import MyTable from '../../components/MyTable';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import Str from '../../constants/string';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '20px'
    },
    body: {
        width: '100%',
        height: '100%',
    },
    modalTitle: {
        margin: '1em'
    },
    popupAddDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '400px',
            height: '660px'
        }
    },
    popupEditDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '400px',
            height: '710px'
        }
    },
    button_div: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px'
    }
}))

const Payments = (props) => {
    const { history } = props;
    const classes = useStyle();
    const selectList = [10, 20, 50, 100, 200, -1];
    const [dataList, setDataList] = useState([]);
    const [allDataList, setAllDataList] = useState([]);
    const [row_count, setRowCount] = useState(selectList[0]);
    const [totalpage, setTotalPage] = useState(1);
    const [page_num, setPageNum] = useState(1);
    const [sort_column, setSortColumn] = useState(7);
    const [sort_method, setSortMethod] = useState(Str.STR_DESC);
    const columns = [];
    // const [startDate, setStartDate] = useState(new Date().setHours(1, 0, 0, 0))
    // const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 59))
    const [refresh, setRefresh] = useState(false);
    const [detailsModal, setDetailsModal] = useState({isOpen: false, text: ""});

    const cellList = [
        { key: 'id', field: 'ID' },
        { key: 'receptionName', field: 'Reception' },
        { key: 'locationName', field: 'Location' },
        { key: 'patientName', field: 'Patient Name' },
        { key: 'amount', field: 'Amount' },
        { key: 'status', field: 'Status' },
        { key: 'dc_cmdStatus', field: 'CMD Status' },
        { key: 'createAt', field: 'Create At' },
    ];

    for (let i = 0; i < cellList.length; i++) {
      columns[i] = Str.STR_ASC;
    }

    useEffect(() => {
      var token = authService.getToken();
      if (!token) {
          window.location.replace("/");
      }

      getPayments();
    }, []);

    useEffect(() => {
      updateTable();
    }, [page_num, row_count, sort_column, sort_method, refresh]);

    /**
     * Function to sort array of user information
     */
    function updateTable() {
      var listData = [...allDataList];
      var sortmethod = sort_method === Str.STR_ASC ? 1 : -1;
      var startIndex = (page_num - 1) * row_count;
      var endIndex = (page_num - 1) * row_count + row_count;
      var pageCount = Math.ceil(listData.length / row_count);
      setTotalPage(pageCount);
      listData.sort(function (a, b) {
          if (isNaN(a[`${cellList[sort_column].key}`])) {
              var nameA = a[`${cellList[sort_column].key}`] === null || a[`${cellList[sort_column].key}`] === undefined ? "" : a[`${cellList[sort_column].key}`].toLowerCase();
              var nameB = b[`${cellList[sort_column].key}`] === null || b[`${cellList[sort_column].key}`] === undefined ? "" : b[`${cellList[sort_column].key}`].toLowerCase();
              if (nameA < nameB) //sort string ascending
                  return -1 * sortmethod;
              if (nameA > nameB)
                  return 1 * sortmethod;
              return 0; //default return value (no sorting)
          }
          else {
              var numA = a[`${cellList[sort_column].key}`], numB = b[`${cellList[sort_column].key}`];
              if (numA < numB) { return -1 * sortmethod; }
              if (numA > numB) { return 1 * sortmethod; }
              return 0;
          }
      });

      setAllDataList(listData);
      setDataList(listData.slice(startIndex, endIndex));
    }

    async function getPayments() {
      try{
        const paymentsRes = await AdminService.getPayments()
        const paymentsJson = paymentsRes.data;
        if(paymentsJson.code !== 0) {
          ToastsStore.error(paymentsJson.message);
          return
        } 
        
        setAllDataList(paymentsJson.data.payments)
        setRefresh(!refresh);
      } catch (err) {
        ToastsStore.error(err.message);
      }
    }

    const handleChangeSelect = (value) => {
        setRowCount(selectList[value]);
    }

    const handleChangePagination = (value) => {
        setPageNum(value);
    }

    const handleSort = (index, direct) => {
        setSortColumn(index);
        setSortMethod(direct);
    }

    const onClickMonitor = () => {
        history.push('/monitor/' + props.match.params.clientid + '/' + props.match.params.username);
    }

    const onRowClick = (item, index) => {
      const detailsObj = {
        id: item.id,
        "create at": item.createAt,
        amount: item.amount,
        reception: {id: item.receptionId, username: item.receptionName},
        location: {id: item.locationId, username: item.locationName},
        patient: item.patientName,
        description: item.description,
        status: item.status,
        "DataCap request": JSON.parse(item.dc_req),
        "DataCap response": JSON.parse(item.dc_res)
      }
      const detailsStr = JSON.stringify(detailsObj, null, 2)
      setDetailsModal({isOpen: true, text: detailsStr})
    }

    const handleDetailsModalClose = () => {
      setDetailsModal({isOpen: false, text: ""})
    }

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.body}>
                    <div className={classes.button_div}>
                        <MyButton name="Monitor" color={"1"} onClick={onClickMonitor} />
                        {/* <KeyboardDateTimePicker
                            format="yyyy-MM-dd HH:mm:ss"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            disableFuture={true}
                            views={['date']}
                        />
                        ~
                        <KeyboardDateTimePicker
                            format="yyyy-MM-dd HH:mm:ss"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            disableFuture={true}
                            views={['date']}
                        /> */}
                    </div>
                    <MyTable
                        onChangeSelect={handleChangeSelect}
                        onChangePage={handleChangePagination}
                        onSelectSort={handleSort}
                        page={page_num}
                        totalpage={totalpage}
                        columns={columns}
                        products={dataList}
                        cells={cellList}
                        editable={false}
                        onRowClick={onRowClick}
                    />
                </div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
            </MuiPickersUtilsProvider>

            <Dialog
              fullWidth={true}
              maxWidth="lg"
              open={detailsModal.isOpen}
              onClose={handleDetailsModalClose}
            >
              <DialogTitle style={{paddingBottom: "0px"}}><h3 style={{marginBottom: "0px"}}>Payment details</h3></DialogTitle>
              <DialogContent>
                <pre>{detailsModal.text}</pre>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDetailsModalClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>
    )
}

export default Payments;