import React, { useEffect } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { withRouter } from 'react-router-dom';
import useStyles from './useStyles';
import Grid from '@material-ui/core/Grid';
import { Button, Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import AdminService from '../../services/api';
import Str from '../../constants/string';
import Constant from '../../constants/constant';

const Login = (props) => {
    const classes = useStyles();
    const { history } = props;
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorsUsername, setErrorsUsername] = React.useState('');
    const [errorsPassword, setErrorsPassword] = React.useState('');
    const [isRemember, setIsRemember] = React.useState(false);

    useEffect(() => {
        setUsername(localStorage.getItem(Str.STR_LOCAL_USER));
        setPassword(localStorage.getItem(Str.STR_LOCAL_PWD));
        setIsRemember(localStorage.getItem(Str.STR_LOCAL_IS_REMEMBER) == 'true' ? true : false);
    }, []);

    document.onkeydown = function (e) {
        if (e.keyCode === 13) {
            if (document.getElementById('login')) {
                handleClickButton();
            }
        }
    }

    const handleChangeUsername = (event) => {
        event.preventDefault();
        setUsername(event.target.value);
        if (event.target.value.length > 0) {
            setErrorsUsername('');
        }
    }
    
    const handleChangePassword = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
        if (event.target.value.length > 0) {
            setErrorsPassword('');
        }
    }

    const handleClickButton = () => {
        let cnt = 0;
        if (username.length === 0) { setErrorsUsername(Str.STR_INPUT_USERNAME); cnt++; }
        if (password.length === 0) { setErrorsPassword(Str.STR_INPUT_PASSWORD); cnt++; }
        if (cnt === 0) {
            login();
        }
    }

    const handleRememberMe = (event) => {
        setIsRemember(event.target.checked);
    }

    const login = () => {
        if (isRemember == true) {
            localStorage.setItem(Str.STR_LOCAL_USER, username);
            localStorage.setItem(Str.STR_LOCAL_PWD, password);
        }
        else {
            localStorage.setItem(Str.STR_LOCAL_USER, "");
            localStorage.setItem(Str.STR_LOCAL_PWD, "");
        }
        localStorage.setItem(Str.STR_LOCAL_IS_REMEMBER, isRemember);

        var data = {};
        data['username'] = username;
        data['password'] = password;
        AdminService.loginClient(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        ToastsStore.success(response.data.message);
                        var data = response.data.data.data;
                        var token = response.data.data.token;
                        localStorage.setItem("token", JSON.stringify(token));
                        switch (data.type) {
                            case Constant.ADMIN:
                                history.push('/admin/' + data.username);
                                break;
                            case Constant.CLIENT:
                                history.push('/monitor/' + data.id + '/' + data.username);
                                break;
                            default:
                                ToastsStore.error(Str.STR_NO_PERMISSION);
                                break;
                        }
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    return (
        <div className={classes.divContainer}>
            <Grid container direction="column" justify="flex-start" className={classes.root}>
                <Grid item container justify="center">
                    <p className={classes.title}>Welcome to your personal login area.<br /> Please enter your User ID and personal password to log in.</p>
                </Grid>
                <Grid item container justify="center">
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                    <Grid xs={10} sm={7} md={4} item container direction="column" className={classes.body}>
                        <Grid item></Grid>
                        <Grid item container justify="center">
                            <p className={classes.boxTitle}><b>Log in</b></p>
                        </Grid>
                        <Grid item container className={classes.input}>
                            <Grid xs={1} item></Grid>
                            <Grid xs={10} item container direction="column" spacing={2}>
                                <Grid item><p className={classes.itemTitle}>User ID</p></Grid>
                                <Grid item container direction="column">
                                    <TextField
                                        name="username"
                                        type="username"
                                        value={username}
                                        fullWidth
                                        onChange={handleChangeUsername}
                                        noValidate
                                        variant="outlined"
                                    />
                                    {errorsUsername.length > 0 &&
                                        <span className={classes.error}>{errorsUsername}</span>}
                                </Grid>
                                <Grid item><p className={classes.itemTitle}>Password</p></Grid>
                                <Grid item container direction="column">
                                    <TextField
                                        name="password"
                                        variant="outlined"
                                        fullWidth
                                        value={password}
                                        onChange={handleChangePassword}
                                        type="password"
                                        noValidate
                                    />
                                    {errorsPassword.length > 0 &&
                                        <span className={classes.error}>{errorsPassword}</span>}
                                </Grid>
                                <Grid item container direction="row" style={{ alignItems: 'center' }}>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={isRemember}
                                        onChange={handleRememberMe}
                                    />
                                    <p className={classes.remember}>Remember me</p>
                                </Grid>
                            </Grid>
                            <Grid xs={1} item></Grid>
                        </Grid>
                        <Grid item container justify="center">
                            <Button className={classes.button1} onClick={handleClickButton} id="login">
                                Log in
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                </Grid>
                <Grid item container justify="center">
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                    <Grid item container xs={10} sm={7} md={4}>
                        <Grid item container direction="row-reverse">
                            <Link href="#/forgotpassword" variant="body2">
                                <p className={classes.forgot}>Forgot my password</p>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                </Grid>
            </Grid>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        </div>
    );
};
export default withRouter(Login);
