import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import * as $ from 'jquery';
import AdminService from '../../services/api';
import authService from '../../services/authService.js';
import Constant from '../../constants/constant';
import MyButton from '../../components/MyButton';
import MyTable from '../../components/MyTable';
import DeleteConfirmDialog from '../../components/DeleteConfirmDialog';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import Str from '../../constants/string';
import AddClientMember from '../Dialog/AddClientMember';
import EditClientMember from '../Dialog/EditClientMember';
import { func } from 'prop-types';
import UpdatePassword from '../Dialog/UpdatePassword';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '20px'
    },
    body: {
        width: '100%',
        height: '100%',
    },
    modalTitle: {
        margin: '1em'
    },
    popupDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '400px',
            height: '780px'
        }
    }
}))

const Administrator = (props) => {
    const { history } = props;
    const classes = useStyle();
    const selectList = [10, 20, 50, 100, 200, -1];
    const [openAdd, setOpenAdd] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [editUserInfo, setEditUserInfo] = React.useState(null);
    const [state, setState] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [allDataList, setAllDataList] = useState([]);
    const [totalpage, setTotalPage] = useState(1);
    const [row_count, setRowCount] = useState(selectList[0]);
    const [page_num, setPageNum] = useState(1);
    const [sort_column, setSortColumn] = useState(0);
    const [sort_method, setSortMethod] = useState(Str.STR_ASC);
    const columns = [];
    const [openDelete, setOpenDelete] = React.useState(false);
    const [deleteItem, setDeleteItem] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);
    const [openChangePass,setOpenChangePass]=React.useState(false)
    const cellList = [
        { key: 'username', field: 'Name' },
        { key: 'title', field: 'Title' },
        { key: 'company', field: 'Company Name' },
        { key: 'address', field: 'Address' },
        { key: 'city', field: 'City' },
        { key: 'state', field: 'State' },
        { key: 'zipcode', field: 'Zip Code' },
        { key: 'email', field: 'Email' },
        { key: 'roomname', field: 'Room Name' },
        { key: 'locationnum', field: 'Location Limit' },
        { key: 'receptionnum', field: 'Receptionist Limit' },
    ];

    for (let i = 0; i < cellList.length; i++) {
        columns[i] = Str.STR_ASC;
    }

    useEffect(() => {
        var token = authService.getToken();
        if (!token) {
            window.location.replace("/");
        }

        getAllClientInfomation(cellList[0].key, Str.STR_ASC);
        let intervalId = setInterval(getInformation, 1000 * 60 * 7)
        return (() => {
            clearInterval(intervalId)
        })
    }, []);

    useEffect(() => {
        updateTable();
    }, [page_num, row_count, sort_column, sort_method, refresh]);

    /**
     * Function to sort array of client information
     */
    function getInformation() {
        getAllClientInfomation(cellList[0].key, Str.STR_ASC);
    }

    /**
     * Function to sort array of client information
     */
    function updateTable() {
        var listData = [...allDataList];
        var sortmethod = sort_method === Str.STR_ASC ? 1 : -1;
        var startIndex = (page_num - 1) * row_count;
        var endIndex = (page_num - 1) * row_count + row_count;
        var pageCount = Math.ceil(listData.length / row_count);
        setTotalPage(pageCount);
        listData.sort(function (a, b) {
            if (isNaN(a[`${cellList[sort_column].key}`])) {
                var nameA = a[`${cellList[sort_column].key}`].toLowerCase(), nameB = b[`${cellList[sort_column].key}`].toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1 * sortmethod;
                if (nameA > nameB)
                    return 1 * sortmethod;
                return 0; //default return value (no sorting)
            }
            else {
                var numA = a[`${cellList[sort_column].key}`], numB = b[`${cellList[sort_column].key}`];
                if (numA < numB) { return -1 * sortmethod; }
                if (numA > numB) { return 1 * sortmethod; }
                return 0;
            }
        });

        setAllDataList(listData);
        setDataList(listData.slice(startIndex, endIndex));
    }

    /**
    * Get all information of user from user database of backend server
    * @param sortcolumn: name of sorting column
    * @param sortmethod: direction of sorting
    */
    function getAllClientInfomation(sortcolumn, sortmethod) {
        var data = {
            'sort_column': sortcolumn,
            'sort_method': sortmethod
        };

        AdminService.getAllClientInfomation(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        var listUsers = response.data.data.data;
                        setAllDataList(listUsers);
                        setRefresh(!refresh);
                        ToastsStore.success(Str.STR_UPDATED_ALL_USER);
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    const handleClickEdit = (id) => {
        allDataList.map((cell, index) => {
            if (cell.id === id) {
                setEditUserInfo(cell);
                setOpenEdit(true);
                return;
            }
        });
    };
    const handleClickDelete = (item) => {
        setDeleteItem(item);
        setOpenDelete(true);
    };

    const handleChangeSelect = (value) => {
        console.log("select value", value);
        setRowCount(selectList[value]);
    }
    const handleChangePagination = (value) => {
        setPageNum(value);
    }
    const handleSort = (index, direct) => {
        setSortColumn(index);
        setSortMethod(direct);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleDelete = () => {
        handleCloseDelete();
        let data = {
            'id': deleteItem.id
        }
        AdminService.deleteClientInfomation(data)
            .then(
                response => {
                    ToastsStore.success(Str.STR_DELETED_CLIENT);
                    getAllClientInfomation(cellList[0].key, Str.STR_ASC);
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    const onClickAdd = () => {
        setOpenAdd(true);
    }

    const onClickLogout = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        history.push('/');
    }

    const handleAdd = () => {
        ToastsStore.success(Str.STR_ADDED_NEW_CLIENT);
        getAllClientInfomation(cellList[0].key, Str.STR_ASC);
        setOpenAdd(false);
    };

    const handleEdit = () => {
        ToastsStore.success(Str.STR_UPDATED_CLIENT);
        getAllClientInfomation(cellList[0].key, Str.STR_ASC);
        setOpenEdit(false);
    };

    const handleClose = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setOpenChangePass(false);
    };

    const onClickChangePass=()=>{
        setOpenChangePass(true)
    }

    return (
        <div className={classes.root}>
            <div className={classes.body}>
                <div>
                    <MyButton name={Str.STR_ADD_CLIENT} color={"1"} onClick={onClickAdd} />
                    <MyButton name={Str.CHANGE_PASSWORD} color={"1"} onClick={onClickChangePass} />
                    <MyButton name={Str.STR_LOGOUT} onClick={onClickLogout} />
                </div>
                <MyTable
                    onChangeSelect={handleChangeSelect}
                    onChangePage={handleChangePagination}
                    onSelectSort={handleSort}
                    page={page_num}
                    columns={columns}
                    products={dataList}
                    totalpage={totalpage}
                    cells={cellList}
                    editable={true}
                    onClickEdit={handleClickEdit}
                    onClickDelete={handleClickDelete}
                />
            </div>
            <DeleteConfirmDialog
                openDelete={openDelete}
                handleCloseDelete={handleCloseDelete}
                handleDelete={handleDelete}
                deleteItem={deleteItem}
            />
            <Dialog
                className={classes.popupDialog}
                open={openAdd}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid item container className={classes.padding} justify="space-between">
                    <Grid item container direction="row-reverse"><CloseIcon onClick={handleClose} className={classes.close} /></Grid>
                    <Grid item><h2 id="transition-modal-title" className={classes.modalTitle}>Add new client information</h2></Grid>
                </Grid>
                <AddClientMember onCancel={handleClose} onAdd={handleAdd} refresh={refresh} />
            </Dialog>
            <Dialog
                className={classes.popupDialog}
                open={openEdit}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid item container className={classes.padding} justify="space-between">
                    <Grid item container direction="row-reverse"><CloseIcon onClick={handleClose} className={classes.close} /></Grid>
                    <Grid item><h2 id="transition-modal-title" className={classes.modalTitle}>Update Client Details</h2></Grid>
                </Grid>
                <EditClientMember onCancel={handleClose} onEdit={handleEdit} editUserInfo={editUserInfo} refresh={refresh} />
            </Dialog>
            <Dialog
                // className={classes.popupDialog}
                open={openChangePass}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid item container className={classes.padding} justify="space-between">
                    <Grid item container direction="row-reverse" ><CloseIcon  onClick={handleClose} className={classes.close} /></Grid>
                    <Grid item><h2 id="transition-modal-title" className={classes.modalTitle}>Change Your Password</h2></Grid>
                </Grid>
                <UpdatePassword onCancel={handleClose} />
            </Dialog>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        </div>
    )
}

Administrator.prototype = {

}

export default Administrator;