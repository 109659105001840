import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'
import { Chart, registerables, ArcElement } from "chart.js";
Chart.register(...registerables);
Chart.register(ArcElement);

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
  .chart-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 20px;
    .header-card {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border-radius: 3px;
      border: #aaa 1px solid;
      margin-right: 20px;
      padding: 0 10px;
    }
  }
  .chart-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    .doughnut {
      padding: 10px;
      width: fit-content;
    }
    .chart-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      padding: 10px;
      .distribute-label {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px;
        .distribute-name {
          text-align: left;
          font-size: 13px;
        }
      }
    }
  }
`

const LabelColor = styled.div`
//   background-color: ${({ color }) => color};
  height: 20px;
  width: 20px;
  min-width: 20px;
  border: 1px #aaa solid;
  border-radius: 3px;
  margin-right: 20px;
`

const chartColors = ['#0066cc', '#4cb140', '#f4c145']//, '#009596', '#ec7a08'

const distributeLabel = ['Free', 'Call', 'Away']

const DistributeChart = ({ symbol, distributeData }) => {
    const data = {
        labels: distributeLabel,
        datasets: [
            {
                data: distributeData,
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
            },
        ],
    }

    const options = {
        legend: {
            display: false,
            position: 'right',
        },
        responsive: false,
        maintainAspectRatio: true,
        elements: {
            arc: {
                borderWidth: 0,
            },
            center: {
                text: symbol
            }
        },
        cutoutPercentage: 80,
        layout: {
            padding: 2
        }
    }

    const plugins = [
        {
            beforeDraw: (chart) => {
                const { width, height, ctx } = chart
                ctx.restore()
                const fontSize = Number((height / 100).toFixed(2))
                ctx.font = `20px sans-serif`
                ctx.fillStyle = '#000'
                ctx.textBaseline = 'top'
                let { text } = chart.config.options.elements.center
                let textX = Math.round((width - ctx.measureText(text).width) / 2)
                let textY = height / 2 - 16 * fontSize + 35
                ctx.fillText(text, textX, textY)

                ctx.font = `20px sans-serif`
                ctx.textBaseline = 'top'
                text = 'Duty Time'
                textX = Math.round((width - ctx.measureText(text).width) / 2)
                textY = height / 2 + 25
                ctx.fillStyle = '#000'
                ctx.fillText(text, textX, textY)

                ctx.save()
            },
        },
    ]

    const renderLegend = () => {
        const labels = distributeLabel.map((label, index) => (
            <div className="distribute-label" key={label}>
                <LabelColor style={{ backgroundColor: chartColors[index] }} />
                <div className="distribute-name">{`${distributeLabel[index]} ${getTimeString(distributeData[index])}`}</div>
            </div>
        ))
        return labels
    }

    const getTimeString = (time) => {
        let ret = ''
        const h = Math.floor(time / 60 / 60 / 1000)
        const m = Math.floor(time / 60 / 1000) % 60
        const s = Math.floor(time / 1000) % 60

        if (h > 0)
            ret = ret + `${h}h`

        if (m > 0)
            ret = ret + ` ${m}m`

        if (s > 0)
            ret = ret + ` ${s}s`

        if(ret === '')
            ret = '0s'

        return ret
    }

    return (
        <ChartWrapper>
            <div className="chart-header">
                <div className="header-card">Mill Mountain</div>
                <div className="header-str">Duty Area</div>
            </div>
            <div className="chart-content">
                <div className="doughnut">
                    <Doughnut
                        data={data}
                        width={301}
                        height={301}
                        options={options}
                        plugins={plugins}
                    />
                </div>
                <div className="chart-label">{renderLegend()}</div>
            </div>
        </ChartWrapper>
    )
}

export default DistributeChart
