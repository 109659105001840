import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import * as $ from 'jquery';
import AdminService from '../../services/api';
import authService from '../../services/authService.js';
import Constant from '../../constants/constant';
import MyButton from '../../components/MyButton';
import MyTable from '../../components/MyTable';
import DeleteConfirmDialog from '../../components/DeleteConfirmDialog';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import Str from '../../constants/string';
import AddTeamMember from '../Dialog/AddTeamMember';
import EditTeamMember from '../Dialog/EditTeamMember';
import Storage from '../../constants/storage';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '20px'
    },
    body: {
        width: '100%',
        height: '100%',
    },
    modalTitle: {
        margin: '1em'
    },
    button_div: {
        display: 'flex'
    }
}))

const Monitor = (props) => {
    const { history } = props;
    const isLeave = React.useRef(false);
    const classes = useStyle();
    const selectList = [10, 20, 50, 100, 200, -1];
    const [openAdd, setOpenAdd] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [editUserInfo, setEditUserInfo] = React.useState(null);
    const [state, setState] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [allDataList, setAllDataList] = useState([]);
    const [allTableDataList, setAllTableDataList] = useState([]);
    const [row_count, setRowCount] = useState(selectList[0]);
    const [totalpage, setTotalPage] = useState(1);
    const [page_num, setPageNum] = useState(1);
    const [sort_column, setSortColumn] = useState(0);
    const [sort_method, setSortMethod] = useState(Str.STR_ASC);
    const columns = [];
    const [openDelete, setOpenDelete] = React.useState(false);
    const [deleteItem, setDeleteItem] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);
    const [totalLocations, setTotalLocations] = React.useState(0);
    const [listVersions, setListVersions] = React.useState([]);
    const listTypeVersion=['Alpha','Beta','Manual','Stable']

    const cellList = [
        { key: 'username', field: 'Name' },
        { key: 'email', field: 'Email' },
        { key: 'type', field: 'Type' },
        { key: 'groupnum', field: 'Room Group' },
        { key: 'status', field: 'User Status' },
        { key: 'otherpartid', field: 'On Call' },
        { key: 'version', field: 'Version' },
    ];

    for (let i = 0; i < cellList.length; i++) {
        columns[i] = Str.STR_ASC;
    }

    useEffect(() => {
        var token = authService.getToken();
        if (!token) {
            window.location.replace("/");
        }

        getAllUserInfomation(cellList[0].key, Str.STR_ASC);
    }, []);

    useEffect(() => {
        updateTable();
    }, [page_num, row_count, sort_column, sort_method, refresh]);

    /**
     * Function to sort array of user information
     */
    function updateTable() {
        var listData = [...allDataList];
        var sortmethod = sort_method === Str.STR_ASC ? 1 : -1;
        var startIndex = (page_num - 1) * row_count;
        var endIndex = (page_num - 1) * row_count + row_count;
        var pageCount = Math.ceil(listData.length / row_count);
        setTotalPage(pageCount);
        
        listData.sort(function (a, b) {
            
            if (isNaN(a[`${cellList[sort_column].key}`])) {
                var nameA = a[`${cellList[sort_column].key}`] === null || a[`${cellList[sort_column].key}`] === undefined ? "" : a[`${cellList[sort_column].key}`].toLowerCase();
                var nameB = b[`${cellList[sort_column].key}`] === null || b[`${cellList[sort_column].key}`] === undefined ? "" : b[`${cellList[sort_column].key}`].toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1 * sortmethod;
                if (nameA > nameB)
                    return 1 * sortmethod;
                return 0; //default return value (no sorting)
            }
            else {
                var numA = a[`${cellList[sort_column].key}`], numB = b[`${cellList[sort_column].key}`];
                if (numA < numB) { return -1 * sortmethod; }
                if (numA > numB) { return 1 * sortmethod; }
                return 0;
            }
        });

        setAllDataList(listData);
        setDataList(listData.slice(startIndex, endIndex));
    }

    /**
    * Get all information of user from user database of backend server
    * @param sortcolumn: name of sorting column
    * @param sortmethod: direction of sorting
    */
    async function getAllUserInfomation(sortcolumn, sortmethod) {
        var data = {
            'clientid': props.match.params.clientid,
            'sort_column': sortcolumn,
            'sort_method': sortmethod
        };
        var versions=await AdminService.getAllVersions({
            sortColumn:'id',
            sortMethod:Str.STR_DESC,
            isActive:true
        })
        console.log(versions)
        if(versions.data.code !== 0) return ToastsStore.error(versions.data.message);
        versions=versions.data && versions.data.data
        versions=versions.map((item)=>{
            return item.version
        }).map( a => a.split('.').map( n => +n+100000 ).join('.') ).sort()
         .map( a => a.split('.').map( n => +n-100000 ).join('.') ).reverse();

        versions=[...listTypeVersion,...versions]
        setListVersions(versions)
        AdminService.getAllUserInfomation(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        var listStatus = ["Logged Out", "Logged In"];
                        var listType = ["Location", "Receptionist"];
                        var listUsers = response.data.data.data;

                        const num = listUsers.filter((cell) => cell.type === Constant.LOCATION).length
                        setTotalLocations(num)
                        //db data.
                        setAllDataList(listUsers);

                        for (var i = 0; i < listUsers.length; i++) {
                            var status, type;
                            status = listUsers[i].status > Constant.LOGOUT ? 1 : 0;
                            type = listUsers[i].type;
                            listUsers[i].status = listStatus[parseInt(status)];
                            listUsers[i].type = listType[parseInt(type)];
                            listUsers[i].groupnum++;
                        }

                        //view data for table
                        setAllTableDataList(listUsers);
                        setRefresh(!refresh);
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    const handleClickEdit = (id) => {
        allDataList.map((cell, index) => {
            if (cell.id === id) {
                setEditUserInfo(cell);
                setOpenEdit(true);
                return;
            }
        });
    };
    const handleClickDelete = (item) => {
        setDeleteItem(item);
        setOpenDelete(true);
    };

    const handleChangeSelect = (value) => {
        console.log("select value", value);
        setRowCount(selectList[value]);
    }
    const handleChangePagination = (value) => {
        setPageNum(value);
    }
    const handleSort = (index, direct) => {
        setSortColumn(index);
        setSortMethod(direct);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleDelete = () => {
        handleCloseDelete();
        let data = {
            'id': deleteItem.id
        }
        AdminService.deleteUserInfomation(data)
            .then(
                response => {
                    ToastsStore.success(Str.STR_DELETED_USER);
                    getAllUserInfomation(cellList[0].key, Str.STR_ASC);
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    const onClickAdd = () => {
        setOpenAdd(true);
    }

    const onClickLocation = () => {
        localStorage.setItem(Storage.LOCATION_USER_INDEX, -1)
        history.push('/location/' + props.match.params.clientid + '/' + props.match.params.username);
    }

    const onClickReception = () => {
        localStorage.setItem(Storage.RECEPTION_USER_INDEX, -1)
        history.push('/reception/' + props.match.params.clientid + '/' + props.match.params.username);
    }

    const onClickPayments = () => {
      history.push('/payments/' + props.match.params.clientid + '/' + props.match.params.username);
    }

    const handleAdd = () => {
        ToastsStore.success(Str.STR_ADDED_NEW_USER);
        getAllUserInfomation(cellList[0].key, Str.STR_ASC);
        setOpenAdd(false);
    };

    const handleEdit = () => {
        ToastsStore.success(Str.STR_UPDATED_USER);
        getAllUserInfomation(cellList[0].key, Str.STR_ASC);
        setOpenEdit(false);
    };

    const handleClose = () => {
        setOpenAdd(false);
        setOpenEdit(false);
    };

    const onClickLogout = () => {
        localStorage.clear()
        history.push("/");
    };
    const onClickVC =()=>{
        history.push('/vc/' + props.match.params.clientid + '/' + props.match.params.username);
    }

    return (
        <div className={classes.root}>
            <div className={classes.body}>
                <div className={classes.button_div}>
                    <MyButton name={Str.STR_ADD_USER} color={"1"} onClick={onClickAdd} />
                    <MyButton name="Location Info" color={"1"} onClick={onClickLocation} />
                    <MyButton name="Receptionist Info" color={"1"} onClick={onClickReception} />
                    <MyButton name="Payments" color={"1"} onClick={onClickPayments} />
                    <MyButton name="Version Control" color={"1"} onClick={onClickVC} />
                    <MyButton className={classes.logout_btn} name={Str.STR_LOGOUT} onClick={onClickLogout} />
                </div>
                <MyTable
                    onChangeSelect={handleChangeSelect}
                    onChangePage={handleChangePagination}
                    onSelectSort={handleSort}
                    page={page_num}
                    totalpage={totalpage}
                    columns={columns}
                    products={dataList}
                    cells={cellList}
                    editable={true}
                    onClickEdit={handleClickEdit}
                    onClickDelete={handleClickDelete}
                />
            </div>
            <DeleteConfirmDialog
                openDelete={openDelete}
                handleCloseDelete={handleCloseDelete}
                handleDelete={handleDelete}
                deleteItem={deleteItem}
            />
            <Dialog
                open={openAdd}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid item container className={classes.padding} justify="space-between">
                    <Grid item container direction="row-reverse"><CloseIcon onClick={handleClose} className={classes.close} /></Grid>
                    <Grid item><h2 id="transition-modal-title" className={classes.modalTitle}>Add new user information</h2></Grid>
                </Grid>
                <AddTeamMember onCancel={handleClose} onAdd={handleAdd} refresh={refresh} totalLocations={totalLocations} listVersions={listVersions}/>
            </Dialog>
            <Dialog
                open={openEdit}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid item container className={classes.padding} justify="space-between">
                    <Grid item container direction="row-reverse"><CloseIcon onClick={handleClose} className={classes.close} /></Grid>
                    <Grid item><h2 id="transition-modal-title" className={classes.modalTitle}>Update a user information</h2></Grid>
                </Grid>
                <EditTeamMember onCancel={handleClose} onEdit={handleEdit} editUserInfo={editUserInfo} refresh={refresh} totalLocations={totalLocations} listVersions={listVersions}/>
            </Dialog>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        </div>
    )
}

Monitor.prototype = {

}

export default Monitor;