import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import AdminService from '../../services/api.js';
import Link from '@material-ui/core/Link';
import useStyles from './useStyles';
import Str from '../../constants/string';
import Constant from '../../constants/constant';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

const ForgotPassword = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [errorsEmail, setErrorsEmail] = useState('');
    
    document.onkeydown = function (e) {
        if (e.keyCode === 13) {
            if (document.getElementById('send')) {
                handleClickSend();
            }
        }
    }

    const handleChangeEmail = (event) => {
        event.preventDefault();
        let errorsMail =
            validEmailRegex.test(event.target.value)
                ? ''
                : Str.STR_EMAIL_INVALID;
        setEmail(event.target.value);
        setErrorsEmail(errorsMail);
    }

    const handleClickSend = () => {
        let cnt = 0;
        if (email.length === 0) { setErrorsEmail('please enter your eamil'); cnt++; }
        if (cnt === 0) {
            if (validateForm(errorsEmail)) {
                var data = {};
                data['email'] = email;
                data['type'] = Constant.CLIENT;

                AdminService.forgotPassword(data)
                    .then(
                        response => {
                            if (response.data.code !== 200) {
                                ToastsStore.error(response.data.message);
                            } else {
                                ToastsStore.success(Str.STR_SEND_MESSAGE);
                                history.push('/success');
                            }
                        },
                        error => {
                            ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                        }
                    );
            }
            else setErrorsEmail(Str.STR_EMAIL_INVALID);
        }
    }
    return (
        <div className={classes.divContainer}>
            <Grid container direction="column" justify="flex-start" className={classes.root}>
                <Grid item container justify="center">
                    <p className={classes.title}>Forgot your password? Please enter your email address. You will receive <br />a link to create a new password by email.</p>
                </Grid>
                <Grid item container justify="center">
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                    <Grid xs={10} sm={7} md={4} item container direction="column" className={classes.body}>
                        <Grid item></Grid>
                        <Grid item>
                            <Grid item container justify="center">
                                <p className={classes.boxTitle}><b>Forgot your password</b></p>
                            </Grid>

                            <Grid item container className={classes.input}>
                                <Grid xs={1} item></Grid>
                                <Grid xs={10} item container direction="column" spacing={2}>
                                    <Grid item><p className={classes.itemTitle}>Email</p></Grid>
                                    <Grid item container direction='column'>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={email}
                                            onChange={handleChangeEmail}
                                        />
                                        {errorsEmail.length > 0 &&
                                            <span className={classes.error}>{errorsEmail}</span>}
                                    </Grid>
                                </Grid>
                                <Grid xs={1} item></Grid>
                            </Grid>
                        </Grid>
                        <Grid item container justify="center">
                            <Button className={classes.button1} onClick={handleClickSend} id='send'>
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                </Grid>
                <Grid item container justify="center">
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                    <Grid item container xs={10} sm={7} md={4}>
                        <Grid item container direction="row-reverse">
                            <Link href="#/login" variant="body2">
                                <p className={classes.forgot}>To log in</p>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                </Grid>
            </Grid>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        </div>
    );
};

export default withRouter(ForgotPassword);