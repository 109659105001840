import React, { useState, useRef, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { io } from "socket.io-client";

const browserHistory = createBrowserHistory();




// export default class App extends Component {
const App = () => {
  const isSecondRender = useRef(false)
  const [socket, setSocket] = useState(null)
  const jsonData = {
    name: "Bonsak",
    payment: 45
  }

  const anotherId = "t6yifSgJSFSKJZi2AAAH"

  useEffect(() => {
    setSocket(io(process.env.REACT_APP_SOCKET_DNS_NAME))
  }, []);

  socket && socket.on("connect", () => {
    const engine = socket.io.engine;
    console.log(engine.transport.name); // in most cases, prints "polling"
    
    socket.emit("private", anotherId, jsonData);

    engine.once("upgrade", () => {
      // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
      console.log(engine.transport.name); // in most cases, prints "websocket"
    });

    engine.on("packet", ({ type, data }) => {
      // called for each packet received
    });

    engine.on("packetCreate", ({ type, data }) => {
      // called for each packet sent
    });

    engine.on("drain", () => {
      // called when the write buffer is drained
    });

    engine.on("close", (reason) => {
      // called when the underlying connection is closed
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
