import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {  MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import AdminService from '../../services/api';
import authService from '../../services/authService.js';
import MyButton from '../../components/MyButton';
import MyTable from '../../components/MyTable';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import Str from '../../constants/string';
import AddVersion from '../Dialog/AddVersion';
import EditVersion from '../Dialog/EditVersion';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '20px'
    },
    body: {
        width: '100%',
        height: '100%',
    },
    modalTitle: {
        margin: '1em'
    },
    popupAddDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '400px',
            height: '660px'
        }
    },
    popupEditDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '400px',
            height: '710px'
        }
    },
    button_div: {
        display: 'flex',
        // justifyContent:'space-between',
        alignItems: 'center',
        gap: '15px'
    }
}))

const VersionControl = (props) => {
    const { history } = props;
    const classes = useStyle();
    const selectList = [10, 20, 50, 100, 200, -1];
    const [dataList, setDataList] = useState([]);
    const [row_count, setRowCount] = useState(selectList[0]);
    const [totalpage, setTotalPage] = useState(1);
    const [page_num, setPageNum] = useState(1);
    const [sort_column, setSortColumn] = useState(0);
    const [sort_method, setSortMethod] = useState(Str.STR_ASC);
    const columns = [];
    const [refresh, setRefresh] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [editItem ,setEditItem] = useState('')
    const cellList = [
        { key: 'version', field: 'Version' },
        { key: 'name', field: 'Name' },
        { key: 'type', field: 'Type' },
        { key: 'isActive', field: 'Active' },
        { key: 'description', field: 'Description' },
        { key: 'created_at', field: 'Upload At' },
    ];

    for (let i = 0; i < cellList.length; i++) {
        columns[i] = Str.STR_ASC;
    }

    useEffect(() => {
        var token = authService.getToken();
        if (!token) {
            window.location.replace("/");
        }
        getAllVersions()
    }, []);

    async function getAllVersions(){
        var data={
            sortColumn:cellList[0].key,
            sortMethod:Str.STR_ASC
        }
        var res=await AdminService.getAllVersions(data)
        if(res.data.code !== 0) return ToastsStore.error(res.data.message);
        var versions=res.data.data
        setDataList(versions)
        setRefresh(!refresh);

    }

    useEffect(() => {
        updateTable();
    }, [page_num, row_count, sort_column, sort_method, refresh]);

    /**
     * Function to sort array of user information
     */
    function updateTable() {
        var listData = [...dataList];
        var sortmethod = sort_method === Str.STR_ASC ? 1 : -1;
        var startIndex = (page_num - 1) * row_count;
        var endIndex = (page_num - 1) * row_count + row_count;
        var pageCount = Math.ceil(listData.length / row_count);
        setTotalPage(pageCount);
        listData.sort(function (a, b) {
            if (isNaN(a[`${cellList[sort_column].key}`])) {
                var nameA = a[`${cellList[sort_column].key}`] === null || a[`${cellList[sort_column].key}`] === undefined ? "" : a[`${cellList[sort_column].key}`].toLowerCase();
                var nameB = b[`${cellList[sort_column].key}`] === null || b[`${cellList[sort_column].key}`] === undefined ? "" : b[`${cellList[sort_column].key}`].toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1 * sortmethod;
                if (nameA > nameB)
                    return 1 * sortmethod;
                return 0; //default return value (no sorting)
            }
            else {
                var numA = a[`${cellList[sort_column].key}`], numB = b[`${cellList[sort_column].key}`];
                if (numA < numB) { return -1 * sortmethod; }
                if (numA > numB) { return 1 * sortmethod; }
                return 0;
            }
        });

        setDataList(listData.slice(startIndex, endIndex));
    }

    const handleClickEdit = (id) => {
        const item = dataList.find((version)=>version.id===id)
        setEditItem(item)
        setOpenEdit(true)
    };
    const handleClickDelete =async (item) => {
        let checkConfirm=window.confirm("Are You Sure?")
        if(!checkConfirm) return 
        let res= await AdminService.deleteVersion({id:item.id})
        if(res.data.code!==0) return ToastsStore.error(res.data.message);
        var versions= dataList.filter((version)=> version.id !=item.id)
        ToastsStore.success(res.data.message)
        setDataList(versions)
    };

    const handleChangeSelect = (value) => {
        setRowCount(selectList[value]);
    }
    const handleChangePagination = (value) => {
        setPageNum(value);
    }
    const handleSort = (index, direct) => {
        setSortColumn(index);
        setSortMethod(direct);
    }

    const onClickMonitor = () => {
        history.push('/monitor/' + props.match.params.clientid + '/' + props.match.params.username);
    }

    const onClickLogout = () => {
        localStorage.clear()
        history.push("/");
    };
    const onClickAddNew =()=>{
        setOpenAdd(true)
    }
    const handleClose=()=>{
        setOpenAdd(false)
    }
    const handleCloseEdit=()=>{
        setEditItem('')
        setOpenEdit(false)
    }
    const onAdd=()=>{
        getAllVersions()
    }
    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.body}>
                    <div className={classes.button_div}>
                        <div>
                            <MyButton name="Monitor" color={"1"} onClick={onClickMonitor} />
                            <MyButton name="Add New" color={"1"} onClick={onClickAddNew} />
                        </div>
                        <MyButton style={{float:"right"}}  className={classes.logout_btn} name={Str.STR_LOGOUT} onClick={onClickLogout} />
                    </div>
                    <MyTable
                        onChangeSelect={handleChangeSelect}
                        onChangePage={handleChangePagination}
                        onSelectSort={handleSort}
                        page={page_num}
                        totalpage={totalpage}
                        columns={columns}
                        products={dataList}
                        cells={cellList}
                        editable={true}
                        downloadFile={true}
                        onClickEdit={handleClickEdit}
                        onClickDelete={handleClickDelete}
                    />
                </div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
            </MuiPickersUtilsProvider>
            <AddVersion handleClose={handleClose} open={openAdd} onAdd={onAdd} />
            <EditVersion handleClose={handleCloseEdit} open={openEdit} onAdd={onAdd} item={editItem} />
        </div>
    )
}

VersionControl.prototype = {

}

export default VersionControl;