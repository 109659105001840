import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MyButton from '../../components/MyButton';
import MySelect from '../../components/MySelect';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { AddTeamMemberStyles as useStyles } from './useStyles';
import AdminService from '../../services/api';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBox from '@material-ui/core/Checkbox';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import { 
    TextField,Select, MenuItem  ,
   } from '@material-ui/core';
import constant from '../../constants/constant';
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validUserNameRegex = RegExp(/^[a-zA-Z0-9_\-]+$/);

const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon"
];

function validFileType(file) {
    return fileTypes.includes(file.type);
}

const EditTeamMember = (props) => {
    const { history, editUserInfo, totalLocations ,listVersions } = props;
    const classes = useStyles();
    const [visibleIndicator, setVisibleIndicator] = React.useState(false);
    const [avatarurl, setAvatarUrl] = React.useState("");
    const [avatar, setAvatar] = React.useState(null);
    const [userName, setUserName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [userType, setUserType] = React.useState(0);
    const [locationName, setLocationName] = React.useState('');
    const typeList = ['Location', 'Receptionist'];
    const [userStatus, setUserStatus] = React.useState(0);
    const userStatusList = ["Logged Out", "Logged In"];
    const [other, setOther] = React.useState(0);
    const otherList = editUserInfo.otherpartid && editUserInfo.otherpartid !== '' ? ["Emputy", editUserInfo.otherpartid] : ["Emputy"];
    const [groupList, setGroupList] = React.useState([]);
    const [userGroup, setUserGroup] = React.useState(0);
    const [sleepTime,setSleepTime]=React.useState(60);
    const [posDeviceID, setPosDeviceID] = React.useState("");
    const [errorsUserName, setErrorsUserName] = React.useState('');
    const [errorsEmail, setErrorsEmail] = React.useState('');
    const [errorsLocationName, setErrorsLocationName] = React.useState('');
    const [isKiosk,setIsKiosk]=React.useState(false)
    const [version, setVersion] = React.useState('');
    const [showInputPassword,setShowInputPassword]=React.useState(false)
    const [password,setPassword]=React.useState('')
    useEffect(() => {
        setUserName(editUserInfo.username);
        setEmail(editUserInfo.email);
        setUserType(typeList.indexOf(editUserInfo.type));
        setLocationName(editUserInfo.locationname);
        setUserStatus(userStatusList.indexOf(editUserInfo.status));
        setSleepTime(editUserInfo && editUserInfo.sleeptime)
        setIsKiosk(editUserInfo.kiosk?true:false); 
        setPosDeviceID(editUserInfo.deviceId)
        setVersion(editUserInfo && editUserInfo.version || 'Manual')
        if (editUserInfo.logo !== null && editUserInfo.logo !== '') {
            setAvatarUrl(process.env.REACT_APP_UPLOAD_URL + editUserInfo.logo);
        }

        if (editUserInfo.otherpartid !== null && editUserInfo.otherpartid !== '') {
            setOther(1)
        }

        let groupNum = Math.ceil(totalLocations / Constant.MAX_LOCATION_NUM)
        let groupArray = Array.from({ length: groupNum }, (_, i) => (i + 1).toString())
        setGroupList(groupArray)
        setUserGroup(editUserInfo.groupnum - 1)
    }, [editUserInfo]);

    const handleClose = () => {
        props.onCancel();
    };

    const handleCreate = () => {
        if (errorsUserName.length > 0 || errorsEmail.length > 0)
            return;

        let cnt = 0;
        if (userName.length === 0) { setErrorsUserName('please enter your user name'); cnt++; }
        else setErrorsUserName('');
        if (userType === Constant.LOCATION && locationName.length === 0) { setErrorsLocationName('please enter your location name'); cnt++; }
        else setErrorsLocationName('');

        if (cnt === 0) {
            if (typeList.indexOf(editUserInfo.type) !== userType) {
                var data = {};
                data['clientid'] = Number(props.match.params.clientid);
                data['type'] = userType;

                AdminService.getNumberofUsers(data)
                    .then(
                        response => {
                            if (response.data.code !== 200) {
                                ToastsStore.error(response.data.message);
                            } else {
                                var data = response.data.data.data[0];
                                if (userType === Constant.LOCATION) {
                                    if (data.locationnum != null && Number(data.locationnum) <= Number(data.count)) {
                                        ToastsStore.error(Str.STR_LOCATION_REACHED_LIMIT);
                                    }
                                    else {
                                        updateUserInformation();
                                    }
                                }
                                else {
                                    if (data.receptionnum != null && Number(data.receptionnum) <= Number(data.count)) {
                                        ToastsStore.error(Str.STR_USER_REACHED_LIMIT);
                                    }
                                    else {
                                        updateUserInformation();
                                    }
                                }
                            }
                        },
                        error => {
                            ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                        }
                    );
            }
            else {
                updateUserInformation();
            }
        }
    }

    const handleLoadFront = (event) => {
        if (event.target.files[0] !== undefined) {
            if (validFileType(event.target.files[0])) {
                if (event.target.files[0].size > Constant.MAX_UPLOADING_SIZE) {
                    ToastsStore.warning(Str.STR_IMAGE_SIZE_LOW_5M);
                } else {
                    setAvatar(event.target.files[0]);
                    setAvatarUrl(URL.createObjectURL(event.target.files[0]));
                }
            }
            else {
                ToastsStore.warning(Str.STR_IMAGE_FORMAT_NOT_CORRECT);
            }
        }
    }

    const handleChangeUserName = (event) => {
        event.preventDefault();
        let errorsName =
            validUserNameRegex.test(event.target.value)
                ? ''
                : 'Username is not valid!';
        setUserName(event.target.value);
        setErrorsUserName(errorsName);
    }

    const handleChangeEmail = (event) => {
        event.preventDefault();
        let errorsMail =
            validEmailRegex.test(event.target.value)
                ? ''
                : 'Email is not valid!';
        setEmail(event.target.value.toLowerCase());
        setErrorsEmail(errorsMail);
    }

    const handleChangeUserType = (val) => {
        setUserType(Number(val));
        if (Number(val) === Constant.RECEPTION) {
            setAvatar(null);
            setAvatarUrl("");
        }
    }

    const handleChangeLocationName = (event) => {
        setLocationName(event.target.value);
    }

    const handleChangeUserGroup = (val) => {
        setUserGroup(Number(val));
    }

    const handleChangeUserStatus = (val) => {
        setUserStatus(Number(val));
    }

    const handleChangeOther = (val) => {
        setOther(Number(val));
    }
    const handleChangeSleepTime = (event) => {
        setSleepTime(event.target.value);
    }
    const handleChangePosDeviceID = (event) => {
      setPosDeviceID(event.target.value);
    }
    const handleChangeKiosk=(event)=>{
        setIsKiosk(event.target.checked)
    }

    /**
     * Function that update user information without password
     */
    function updateUserInformation() {
        let formdata = new FormData();
        formdata.set('id', editUserInfo.id);
        formdata.set('username', userName);
        formdata.set('type', userType);
        formdata.set('locationname', locationName);
        formdata.set('email', email);
        formdata.set('group', userGroup);
        formdata.set('status', userStatus);
        formdata.set('otherpartid', other === 0 ? null : otherList[other]);
        formdata.set('logo', avatar === null ? '' : avatar);
        formdata.set('posDeviceID', posDeviceID);
        formdata.set('version', version);

        if(userType== Constant.LOCATION) {
            formdata.set('sleepTime', sleepTime);
            formdata.set('kiosk', isKiosk);
        }
        if(userType==constant.RECEPTION){
            if(showInputPassword && password && password!=''){
                formdata.set('password',password)
            }
        }

        AdminService.updateUserInformation(formdata)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        props.onEdit();
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }
    function handleChangeVersion(e){
        setVersion(e.target.value)
    }
    function handleShowInputPassword(){
        if(showInputPassword){
            setPassword('')
            setShowInputPassword(false)
        }
        else setShowInputPassword(true)
    }
    function handleChangePassword(e){
        setPassword(e.target.value)
    }
    return (
        <Scrollbars style={{ width: '400px', height: userType === Constant.LOCATION ? '700px' : '470px' }}>
            <div className={classes.root}>
                {
                    visibleIndicator ? <div className={classes.div_indicator}> <CircularProgress className={classes.indicator} /> </div> : null
                }
                <div className={classes.paper} sm={12}>
                    <Grid container spacing={2} >
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>User Name</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={userName}
                                    onChange={handleChangeUserName}
                                    fullWidth
                                    required
                                />
                                {errorsUserName.length > 0 &&
                                    <span className={classes.error}>{errorsUserName}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Email</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={email}
                                    onChange={handleChangeEmail}
                                    fullWidth
                                />
                                {errorsEmail.length > 0 &&
                                    <span className={classes.error}>{errorsEmail}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Type</p></Grid>
                            <Grid xs={9} item container>
                                <MySelect
                                    color="gray"
                                    data={typeList}
                                    onChangeSelect={handleChangeUserType}
                                    value={userType}
                                />
                            </Grid>
                        </Grid>
                        {userType === Constant.LOCATION ?
                            <Grid item container justify="space-between" alignItems="center">
                                <Grid xs={3} item container><p className={classes.title}>Location Name</p></Grid>
                                <Grid xs={9} item container>
                                    <TextField
                                        className={classes.text}
                                        variant="outlined"
                                        value={locationName}
                                        onChange={handleChangeLocationName}
                                        fullWidth
                                        required
                                    />
                                    {errorsLocationName.length > 0 &&
                                        <span className={classes.error}>{errorsLocationName}</span>}
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                        {userType === Constant.LOCATION ?
                            <Grid item container justify="space-between" alignItems="center">
                                <Grid xs={3} item container><p className={classes.title}>Sleep Time (minuntes)</p></Grid>
                                <Grid xs={9} item container>
                                    <TextField
                                        className={classes.text}
                                        variant="outlined"
                                        value={sleepTime}
                                        onChange={handleChangeSleepTime}
                                        fullWidth
                                        type='number'

                                    />
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Group</p></Grid>
                            <Grid xs={9} item container>
                                <MySelect
                                    color="gray"
                                    data={groupList}
                                    value={userGroup}
                                    onChangeSelect={handleChangeUserGroup}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>User Status</p></Grid>
                            <Grid xs={9} item container>
                                <MySelect
                                    color="gray"
                                    data={userStatusList}
                                    onChangeSelect={handleChangeUserStatus}
                                    value={userStatus}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>On Call</p></Grid>
                            <Grid xs={9} item container>
                                <MySelect
                                    color="gray"
                                    data={otherList}
                                    onChangeSelect={handleChangeOther}
                                    value={other}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Version: </p></Grid>
                            <Grid xs={9} item container>
                            <Select
                                labelId="demo-simple-select-label"
                                value={version}
                                label="Version"
                                required
                                fullWidth
                                onChange={handleChangeVersion}
                            >
                                {
                                    listVersions.map((v)=>{
                                        return <MenuItem value={v}>{v}</MenuItem>
                                    })
                                }
                            </Select>
                                {errorsLocationName.length > 0 &&
                                    <span className={classes.error}>{errorsLocationName}</span>}
                            </Grid>
                        </Grid> 
                        {userType === Constant.RECEPTION ?

                            <Grid item container justify="space-between" alignItems="center">
                                <Grid xs={12} item container >
                                    <a href='javascript:void(0)' style={{paddingBottom:'0.5rem'}} onClick={handleShowInputPassword} className={classes.title}>Change Password</a>
                                </Grid>

                                {showInputPassword? 
                                    <Grid item container justify="space-between" alignItems="center">
                                        <Grid xs={3} item container><p className={classes.title}>New Password:</p></Grid>
                                        <Grid xs={9} item container>
                                            <TextField
                                            className={classes.text}
                                            variant="outlined"
                                            value={password}
                                            onChange={handleChangePassword}
                                            fullWidth
                                        />
                                        </Grid>
                                    </Grid>
                                    :""
                                }
  
                            </Grid>:""

                        }
                        {userType === Constant.LOCATION ?
                            <Grid item container justify="space-between" alignItems="center">
                                <Grid xs={3} item container><p className={classes.title}>Kiosk</p></Grid>
                                <Grid xs={9} item container>
                                    <CheckBox
                                        checked={isKiosk}
                                        onChange={handleChangeKiosk}

                                    />
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                        {userType === Constant.LOCATION ?
                            <Grid item container justify="space-between" alignItems="center">
                                <Grid xs={3} item container><p className={classes.title}>POS Device ID</p></Grid>
                                <Grid xs={9} item container>
                                    <TextField
                                        className={classes.text}
                                        variant="outlined"
                                        value={posDeviceID}
                                        onChange={handleChangePosDeviceID}
                                        fullWidth
                                        type='test'
                                        placeholder='3011087735277460'
                                    />
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                        {userType === Constant.LOCATION ?
                            <Grid xs={12} item container direction="row">
                                <Grid xs={3} item container><p className={classes.title}>Photo</p></Grid>
                                <Grid xs={9} item container>
                                    <input className={classes.input} accept="image/*" type="file" id="img_front" onChange={handleLoadFront} />
                                    <label className={classes.img_lab} htmlFor="img_front">
                                        <div className={classes.img_div}>
                                            {
                                                avatarurl === '' ?
                                                    <AddCircleOutlineIcon className={classes.plus} />
                                                    :
                                                    <img className={classes.img} src={avatarurl} alt="" />
                                            }
                                        </div>
                                    </label>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                    <div className={classes.footer}>
                        <Grid container justify="space-between">
                            <MyButton name={"\u00a0\u00a0\u00a0OK\u00a0\u00a0\u00a0"} color={"1"} onClick={handleCreate} />
                            <MyButton name={"Cancel"} bgColor="grey" onClick={handleClose} />
                        </Grid>
                    </div>
                </div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
            </div>
        </Scrollbars>
    );
};

export default withRouter(EditTeamMember);
