const constant = {
    LOGOUT: 0,
    LOGIN: 1,
    AWAY: 2,
    JOIN: 3,
    INCOMING: 4,
    CALL: 5,
    HOLD: 6,
    LOCATION: 0,
    RECEPTION: 1,
    CLIENT: 2,
    ADMIN: 3,
    RAISE_CALL: 'raise_call',
    END_CALL: 'end_call',
    NONE_VALUE: "none",
    CALL_INCOMING_VALUE: "call_incoming",
    CALL_ACCEPT_VALUE: "call_accept",
    CALL_END_VALUE: "call_end",
    CALL_PAUSE_VALUE: "call_pause",
    CALL_PLAY_VALUE: "call_play",
    MAX_UPLOADING_SIZE: 5242880,
    MAX_LOCATION_NUM: 12,
}

module.exports = constant