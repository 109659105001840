import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import useStyles from './useStyles';

const Success = (props) => {
    const { history } = props;
    const classes = useStyles();

    const handleClickSend = () => {
        history.push('/login');
    }
    return (
        <div className={classes.divContainer}>
            <Grid container direction="column" justify="flex-start" className={classes.root}>
                <Grid item container justify="center">
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                    <Grid xs={10} sm={7} md={4} item container direction="column" className={classes.body}>
                        <Grid item></Grid>
                        <Grid item container justify="center" style={{ padding: 30 }}>
                            <Grid item />
                            <Grid item>
                                <p className={classes.boxTitle}>
                                    An email has just been sent to you to reset your password.
                                </p>
                            </Grid>
                            <Grid item />
                        </Grid>
                        <Grid item container justify="center">
                            <Button className={classes.button1} onClick={handleClickSend} id='send'>
                                To log in
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(Success);