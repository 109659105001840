import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MyButton from '../../components/MyButton';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { AddTeamMemberStyles as useStyles } from './useStyles';
import AdminService from '../../services/api';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import MuiPhoneNumber from 'material-ui-phone-number';
import Str from '../../constants/string';
import Constant from '../../constants/constant';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon"
];

function validFileType(file) {
    return fileTypes.includes(file.type);
}

const EditClientMember = (props) => {
    const { history, editUserInfo } = props;
    const classes = useStyles();
    const [visibleIndicator, setVisibleIndicator] = React.useState(false);
    const [avatarReceptionUrl, setAvatarReceptionUrl] = React.useState("");
    const [avatarReception, setAvatarReception] = React.useState(null);
    const [avatarLocationUrl, setAvatarLocationUrl] = React.useState("");
    const [avatarLocation, setAvatarLocation] = React.useState(null);
    const [userName, setUserName] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [zipcode, setZipCode] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [roomName, setRoomName] = React.useState('');
    const [limitReception, setLimitReception] = React.useState('');
    const [limitLocation, setLimitLocation] = React.useState('');
    const [phonenumber, setPhoneNumber] = React.useState('');

    const [errorsUserName, setErrorsUserName] = React.useState('');
    const [errorsTitle, setErrorsTitle] = React.useState('');
    const [errorsCompany, setErrorsCompany] = React.useState('');
    const [errorsAddress, setErrorsAddress] = React.useState('');
    const [errorsCity, setErrorsCity] = React.useState('');
    const [errorsState, setErrorsState] = React.useState('');
    const [errorsZipCode, setErrorsZipCode] = React.useState('');
    const [errorsEmail, setErrorsEmail] = React.useState('');
    const [errorsRoomName, setErrorsRoomName] = React.useState('');
    const [errorsLimitReception, setErrorsLimitReception] = React.useState('');
    const [errorsLimitLocation, setErrorsLimitLocation] = React.useState('');
    const [errorsPhonenumber, setErrorsPhonenumber] = React.useState('');

    useEffect(() => {
        console.log("111111", editUserInfo);
        setUserName(editUserInfo.username);
        setTitle(editUserInfo.title);
        setCompany(editUserInfo.company);
        setAddress(editUserInfo.address);
        setCity(editUserInfo.city);
        setState(editUserInfo.state);
        setZipCode(editUserInfo.zipcode);
        setEmail(editUserInfo.email);
        setRoomName(editUserInfo.roomname);
        setLimitReception(editUserInfo.receptionnum);
        setLimitLocation(editUserInfo.locationnum);
        if (editUserInfo.logor !== null && editUserInfo.logor !== '') {
            setAvatarReceptionUrl(process.env.REACT_APP_UPLOAD_URL + editUserInfo.logor);
        }
        if (editUserInfo.logol !== null && editUserInfo.logol !== '') {
            setAvatarLocationUrl(process.env.REACT_APP_UPLOAD_URL + editUserInfo.logol);
        }
    }, []);

    const handleClose = () => {
        props.onCancel();
    };

    const handleCreate = () => {
        let cnt = 0;
        if (userName.length === 0) { setErrorsUserName('please enter your user name'); cnt++; }
        else setErrorsUserName('');
        if (title.length === 0) { setErrorsTitle('please enter title of application'); cnt++; }
        else setErrorsTitle('');
        if (company.length === 0) { setErrorsCompany('please enter your company name'); cnt++; }
        else setErrorsCompany('');
        if (address.length === 0) { setErrorsAddress('please enter your address'); cnt++; }
        else setErrorsAddress('');
        if (city.length === 0) { setErrorsCity('please enter your city'); cnt++; }
        else setErrorsCity('');
        if (state.length === 0) { setErrorsState('please enter your state'); cnt++; }
        else setErrorsState('');
        if (zipcode.length === 0) { setErrorsZipCode('please enter your zip code'); cnt++; }
        else setErrorsZipCode('');
        if (email.length === 0) { setErrorsEmail('please enter your email'); cnt++; }
        else setErrorsEmail('');
        if (roomName.length === 0) { setErrorsRoomName('please enter your room name'); cnt++; }
        else setErrorsRoomName('');
        if (limitReception.length === 0) { setErrorsLimitReception('please enter receptionist limit'); cnt++; }
        else setErrorsLimitReception('');
        if (limitLocation.length === 0) { setErrorsLimitLocation('please enter location limit'); cnt++; }
        else setErrorsLimitLocation('');
        // if (phonenumber.length === 0) { setErrorsPhonenumber('please enter your phone number'); cnt++; }
        // else setErrorsPhonenumber('');
        if (cnt === 0) {
            updateClientInfomation();
        }
    }

    const handleLoadReceptionFront = (event) => {
        if (event.target.files[0] !== undefined) {
            if (validFileType(event.target.files[0])) {
                if (event.target.files[0].size > Constant.MAX_UPLOADING_SIZE) {
                    ToastsStore.warning(Str.STR_IMAGE_SIZE_LOW_5M);
                } else {
                    setAvatarReception(event.target.files[0]);
                    setAvatarReceptionUrl(URL.createObjectURL(event.target.files[0]));
                }
            }
            else {
                ToastsStore.warning(Str.STR_IMAGE_FORMAT_NOT_CORRECT);
            }
        }
    }

    const handleLoadLocationFront = (event) => {
        if (event.target.files[0] !== undefined) {
            if (validFileType(event.target.files[0])) {
                if (event.target.files[0].size > Constant.MAX_UPLOADING_SIZE) {
                    ToastsStore.warning(Str.STR_IMAGE_SIZE_LOW_5M);
                } else {
                    setAvatarLocation(event.target.files[0]);
                    setAvatarLocationUrl(URL.createObjectURL(event.target.files[0]));
                }
            }
            else {
                ToastsStore.warning(Str.STR_IMAGE_FORMAT_NOT_CORRECT);
            }
        }
    }

    const handleChangeUserName = (event) => {
        setUserName(event.target.value);
    }

    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleChangeCompany = (event) => {
        setCompany(event.target.value);
    }

    const handleChangeAddress = (event) => {
        setAddress(event.target.value);
    }

    const handleChangeCity = (event) => {
        setCity(event.target.value);
    }

    const handleChangeState = (event) => {
        setState(event.target.value);
    }

    const handleChangeZipCode = (event) => {
        setZipCode(event.target.value);
    }

    const handleChangeEmail = (event) => {
        event.preventDefault();
        let errorsMail =
            validEmailRegex.test(event.target.value)
                ? ''
                : 'Email is not valid!';
        setEmail(event.target.value.toLowerCase());
        setErrorsEmail(errorsMail);
    }

    const handleChangePhoneNumber = (val) => {
        setPhoneNumber(val);
    }

    const handleChangeRoomName = (event) => {
        setRoomName(event.target.value);
    }

    const handleChangeReceptionNum = (event) => {
        setLimitReception(event.target.value);
    }

    const handleChangeLocationNum = (event) => {
        setLimitLocation(event.target.value);
    }

    /**
     * Function that update client information without password
     */
    function updateClientInfomation() {
        let formdata = new FormData();
        formdata.set('id', editUserInfo.id);
        formdata.set('username', userName);
        formdata.set('title', title);
        formdata.set('company', company);
        formdata.set('address', address);
        formdata.set('city', city);
        formdata.set('state', state);
        formdata.set('zipcode', zipcode);
        formdata.set('email', email);
        formdata.set('roomname', roomName);
        formdata.set('locationnum', limitLocation);
        formdata.set('receptionnum', limitReception);
        formdata.set('logor', avatarReception === null ? '' : avatarReception);
        formdata.set('logol', avatarLocation === null ? '' : avatarLocation);

        AdminService.updateClientInfomation(formdata)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        props.onEdit();
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    return (
        <Scrollbars style={{ height: '100vh' }}>
            <div className={classes.root}>
                {
                    visibleIndicator ? <div className={classes.div_indicator}> <CircularProgress className={classes.indicator} /> </div> : null
                }
                <div className={classes.paper} sm={12}>
                    <Grid container spacing={2} >
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>User Name</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={userName}
                                    onChange={handleChangeUserName}
                                    fullWidth
                                />
                                {errorsUserName.length > 0 &&
                                    <span className={classes.error}>{errorsUserName}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Title</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={title}
                                    onChange={handleChangeTitle}
                                    fullWidth
                                />
                                {errorsTitle.length > 0 &&
                                    <span className={classes.error}>{errorsTitle}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Company</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={company}
                                    onChange={handleChangeCompany}
                                    fullWidth
                                />
                                {errorsCompany.length > 0 &&
                                    <span className={classes.error}>{errorsCompany}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Address</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={address}
                                    onChange={handleChangeAddress}
                                    fullWidth
                                />
                                {errorsAddress.length > 0 &&
                                    <span className={classes.error}>{errorsAddress}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>City</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={city}
                                    onChange={handleChangeCity}
                                    fullWidth
                                />
                                {errorsCity.length > 0 &&
                                    <span className={classes.error}>{errorsCity}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>State</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={state}
                                    onChange={handleChangeState}
                                    fullWidth
                                />
                                {errorsState.length > 0 &&
                                    <span className={classes.error}>{errorsState}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Zip Code</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={zipcode}
                                    onChange={handleChangeZipCode}
                                    fullWidth
                                />
                                {errorsZipCode.length > 0 &&
                                    <span className={classes.error}>{errorsZipCode}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Email</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={email}
                                    onChange={handleChangeEmail}
                                    fullWidth
                                />
                                {errorsEmail.length > 0 &&
                                    <span className={classes.error}>{errorsEmail}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Room Name</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    value={roomName}
                                    onChange={handleChangeRoomName}
                                    fullWidth
                                />
                                {errorsRoomName.length > 0 &&
                                    <span className={classes.error}>{errorsRoomName}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Receptionist Limit</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    type='number'
                                    value={limitReception}
                                    onChange={handleChangeReceptionNum}
                                    fullWidth
                                />
                                {errorsLimitReception.length > 0 &&
                                    <span className={classes.error}>{errorsLimitReception}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid xs={3} item container><p className={classes.title}>Location Limit</p></Grid>
                            <Grid xs={9} item container>
                                <TextField
                                    className={classes.text}
                                    variant="outlined"
                                    type='number'
                                    value={limitLocation}
                                    onChange={handleChangeLocationNum}
                                    fullWidth
                                />
                                {errorsLimitLocation.length > 0 &&
                                    <span className={classes.error}>{errorsLimitLocation}</span>}
                            </Grid>
                        </Grid>
                        <Grid xs={12} item container direction="row" >
                            <Grid xs={3} item container><p className={classes.title}>Receptionist Logo</p></Grid>
                            <Grid xs={9} item container>
                                <input className={classes.input} accept="image/*" type="file" id="img_frontr" onChange={handleLoadReceptionFront} />
                                <label className={classes.img_lab} htmlFor="img_frontr">
                                    <div className={classes.img_div}>
                                        {
                                            avatarReceptionUrl === '' ?
                                                <AddCircleOutlineIcon className={classes.plus} />
                                                :
                                                <img className={classes.img} src={avatarReceptionUrl} alt="" />
                                        }
                                    </div>
                                </label>
                            </Grid>
                        </Grid>
                        <Grid xs={12} item container direction="row" >
                            <Grid xs={3} item container><p className={classes.title}>Location Logo</p></Grid>
                            <Grid xs={9} item container>
                                <input className={classes.input} accept="image/*" type="file" id="img_frontl" onChange={handleLoadLocationFront} />
                                <label className={classes.img_lab} htmlFor="img_frontl">
                                    <div className={classes.img_div}>
                                        {
                                            avatarLocationUrl === '' ?
                                                <AddCircleOutlineIcon className={classes.plus} />
                                                :
                                                <img className={classes.img} src={avatarLocationUrl} alt="" />
                                        }
                                    </div>
                                </label>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.footer}>
                        <Grid container justify="space-between">
                            <MyButton name={"\u00a0\u00a0\u00a0OK\u00a0\u00a0\u00a0"} color={"1"} onClick={handleCreate} />
                            <MyButton name={"Cancel"} bgColor="grey" onClick={handleClose} />
                        </Grid>
                    </div>
                </div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
            </div>
        </Scrollbars>
    );
};

export default withRouter(EditClientMember);
