import React, { Component } from 'react';
import { Switch, Redirect, Route, HashRouter } from 'react-router-dom';

import {
    Login,
    ResetPassword,
    ForgotPassword,
    Success,
    Administrator,
    Monitor,
    Location,
    Reception,
    Payments,
    VersionControl
} from './Pages'

class Routes extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Redirect exact from="/" to={"/login"} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/resetpassword/:token" component={ResetPassword} />
                    <Route exact path="/forgotpassword" component={ForgotPassword} />
                    <Route exact path="/success" component={Success} />
                    <Route exact path="/admin/:username" component={Administrator} />
                    <Route exact path="/monitor/:clientid/:username" component={Monitor} />
                    <Route exact path="/location/:clientid/:username" component={Location} />
                    <Route exact path="/reception/:clientid/:username" component={Reception} />
                    <Route exact path="/payments/:clientid/:username" component={Payments} />
                    <Route exact path="/vc/:clientid/:username" component={VersionControl} />
                </Switch>
            </HashRouter>
        )
    }
}

export default Routes;