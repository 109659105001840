import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import DateFnsUtils from '@date-io/date-fns'
import InputBase from '@material-ui/core/InputBase';
import moment from 'moment'
import AdminService from '../../services/api';
import authService from '../../services/authService.js';
import MyButton from '../../components/MyButton';
import MyTable from '../../components/MyTable';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import Str from '../../constants/string';
import Storage from '../../constants/storage';
import DistributeChart from '../../components/DistributeChart'

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            [theme.breakpoints.up('xl')]: {
                marginTop: 24,
            },
            [theme.breakpoints.between('lg', 'lg')]: {
                marginTop: 17,
            },
            [theme.breakpoints.down('md')]: {
                marginTop: 12,
            },
        },
    },
    input: {
        [theme.breakpoints.up('xl')]: {
            fontSize: 17,
            height: 35,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 12,
            height: 35,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 8,
            height: 35,
        },
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #1499ff',
        color: '#1499ff',
        padding: '2px 12px',
        display: 'flex',
        alignItems: 'center',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Poppins',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#1499ff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '20px'
    },
    body: {
        width: '100%',
        height: '100%',
    },
    modalTitle: {
        margin: '1em'
    },
    popupAddDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '400px',
            height: '660px'
        }
    },
    popupEditDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '400px',
            height: '710px'
        }
    },
    button_div: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        marginBottom: '20px'
    }
}))

const Reception = (props) => {
    const { history } = props;
    const classes = useStyle();
    const selectList = [10, 20, 50, 100, 200, -1];
    const [dataList, setDataList] = useState([]);
    const [allDataList, setAllDataList] = useState([]);
    const [calculateDataList, setCalculateDataList] = useState([]);
    const [row_count, setRowCount] = useState(selectList[0]);
    const [totalpage, setTotalPage] = useState(1);
    const [page_num, setPageNum] = useState(1);
    const [sort_column, setSortColumn] = useState(0);
    const [sort_method, setSortMethod] = useState(Str.STR_ASC);
    const columns = [];
    const [refresh, setRefresh] = React.useState(false);
    const [receptionList, setReceptionList] = useState([])
    const [value, setValue] = React.useState(0);
    const [reception, setReception] = useState("")
    const [startDate, setStartDate] = useState(new Date().setHours(1, 0, 0, 0))
    const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 59))
    const [distributeData, setDistributeData] = useState([0, 0, 0])
    const [dutySymbol, setDutySymbol] = useState("")

    const cellList = [
        { key: 'reception', field: 'Reception Name' },
        { key: 'status', field: 'Status' },
        { key: 'created_at', field: 'Time' },
    ];

    for (let i = 0; i < cellList.length; i++) {
        columns[i] = Str.STR_ASC;
    }

    useEffect(() => {
        var token = authService.getToken();
        if (!token) {
            window.location.replace("/");
        }

        getAllReceptionList();
    }, []);

    useEffect(() => {
        if (reception === "" || reception === null)
            return;

        getReceptionLogList();
    }, [reception, startDate, endDate]);

    useEffect(() => {
        if (calculateDataList.length === 0) {
            setDistributeData([0, 0, 0])
            setDutySymbol(getTimeString(0))
            return;
        }

        const loginData = calculateDataList.filter((cell) => cell.status === 'Login' || cell.status === 'Logout')
        let dutyTime = 0;
        let callTime = 0;
        let awayTime = 0;
        loginData.forEach((cell, index) => {
            if (cell.status === 'Login' && index + 1 < loginData.length) {
                const loginTime = new Date(cell.created_at).getTime()
                const logoutTime = new Date(loginData[index + 1].created_at).getTime()
                dutyTime = dutyTime + logoutTime - loginTime
            }
            
            if (cell.status === 'Login' && index === loginData.length - 1) {
                const loginTime = new Date(cell.created_at).getTime()
                const logoutTime = new Date(calculateDataList[calculateDataList.length - 1].created_at).getTime()
                dutyTime = dutyTime + logoutTime - loginTime
            }
        })
        calculateDataList.forEach((cell, index) => {
            if (cell.status === 'Call' && index + 1 < calculateDataList.length) {
                const callStartTime = new Date(cell.created_at).getTime()
                const callEndTime = new Date(calculateDataList[index + 1].created_at).getTime()
                callTime = callTime + callEndTime - callStartTime
            }
            else if (cell.status === 'Away' && index + 1 < calculateDataList.length) {
                const awayStartTime = new Date(cell.created_at).getTime()
                const awayEndTime = new Date(calculateDataList[index + 1].created_at).getTime()
                awayTime = awayTime + awayEndTime - awayStartTime
            }
        })
        
        setDistributeData([dutyTime - callTime - awayTime, callTime, awayTime])
        setDutySymbol(getTimeString(dutyTime))
    }, [calculateDataList]);

    useEffect(() => {
        updateTable();
    }, [page_num, row_count, sort_column, sort_method, refresh]);

    const getTimeString = (time) => {
        let ret = ''
        const h = Math.floor(time / 60 / 60 / 1000)
        const m = Math.floor(time / 60 / 1000) % 60

        if (h > 0)
            ret = ret + `${h}h`

        if (m > 0)
            ret = ret + ` ${m}m`

        if (ret === '')
            ret = '0m'

        return ret
    }

    /**
     * Function to sort array of user information
     */
    function updateTable() {
        var listData = [...allDataList];
        var sortmethod = sort_method === Str.STR_ASC ? 1 : -1;
        var startIndex = (page_num - 1) * row_count;
        var endIndex = (page_num - 1) * row_count + row_count;
        var pageCount = Math.ceil(listData.length / row_count);
        setTotalPage(pageCount);
        listData.sort(function (a, b) {
            if (isNaN(a[`${cellList[sort_column].key}`])) {
                var nameA = a[`${cellList[sort_column].key}`] === null || a[`${cellList[sort_column].key}`] === undefined ? "" : a[`${cellList[sort_column].key}`].toLowerCase();
                var nameB = b[`${cellList[sort_column].key}`] === null || b[`${cellList[sort_column].key}`] === undefined ? "" : b[`${cellList[sort_column].key}`].toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1 * sortmethod;
                if (nameA > nameB)
                    return 1 * sortmethod;
                return 0; //default return value (no sorting)
            }
            else {
                var numA = a[`${cellList[sort_column].key}`], numB = b[`${cellList[sort_column].key}`];
                if (numA < numB) { return -1 * sortmethod; }
                if (numA > numB) { return 1 * sortmethod; }
                return 0;
            }
        });

        setAllDataList(listData);
        setDataList(listData.slice(startIndex, endIndex));
    }

    /**
    * Get logs of a reception from user database of backend server
    * @param sortcolumn: name of sorting column
    * @param sortmethod: direction of sorting
    */
    function getReceptionLogList() {
        let data = {
            'clientid': props.match.params.clientid,
            'reception': reception,
            'starttime': moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            'endtime': moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        };

        AdminService.getReceptionLogList(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        let statusType = ["Logout", "Login", "Away", "Join", "Incoming", "Call", "Hold"];
                        let data = response.data.data.data;
                        setAllDataList(data);
                        setCalculateDataList(data)

                        for (let i = 0; i < data.length; i++) {
                            let type = data[i].status;
                            data[i].status = statusType[parseInt(type)];
                        }

                        setRefresh(!refresh);
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    /**
    * Get reception list from user database of backend server
    * @param sortcolumn: name of sorting column
    * @param sortmethod: direction of sorting
    */
    function getAllReceptionList() {
        let data = {
            'clientid': props.match.params.clientid,
        };

        AdminService.getAllReceptionList(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        let data = response.data.data.data;

                        const receptions = data.map((cell) => (
                            cell.username
                        ))

                        setReceptionList(receptions)
                        if (receptions.length > 0) {
                            const index = localStorage.getItem(Storage.RECEPTION_USER_INDEX)
                            if(index !== undefined && parseInt(index) >= 0 && parseInt(index) < receptions.length) {
                                setValue(index);
                                setReception(receptions[parseInt(index)])
                            }
                            else{
                                setReception(receptions[0])
                            }
                        }
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    const handleClickEdit = (id) => {

    };
    const handleClickDelete = (item) => {

    };

    const handleChangeSelect = (value) => {
        console.log("select value", value);
        setRowCount(selectList[value]);
    }
    const handleChangePagination = (value) => {
        setPageNum(value);
    }
    const handleSort = (index, direct) => {
        setSortColumn(index);
        setSortMethod(direct);
    }

    const onClickMonitor = () => {
        history.push('/monitor/' + props.match.params.clientid + '/' + props.match.params.username);
    }

    const onClickLogout = () => {
        history.push("/");
    };

    const handleChange = (event) => {
        localStorage.setItem(Storage.RECEPTION_USER_INDEX, event.target.value)
        setValue(event.target.value);
        setReception(receptionList[parseInt(event.target.value)])
    };

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.body}>
                    <div className={classes.button_div}>
                        <MyButton name="Monitor" color={"1"} onClick={onClickMonitor} />
                        <FormControl className={classes.margin}>
                            <NativeSelect
                                value={value}
                                onChange={handleChange}
                                input={<BootstrapInput />}
                            >
                                {receptionList.map((select, i) =>
                                    <option value={i} key={select}>{select}</option>
                                )}
                            </NativeSelect>
                        </FormControl>
                        <KeyboardDateTimePicker
                            format="yyyy-MM-dd HH:mm:ss"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            disableFuture={true}
                            views={['date']}
                        />
                        ~
                        <KeyboardDateTimePicker
                            format="yyyy-MM-dd HH:mm:ss"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            disableFuture={true}
                            views={['date']}
                        />
                        <MyButton className={classes.logout_btn} name={Str.STR_LOGOUT} onClick={onClickLogout} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <MyTable
                            onChangeSelect={handleChangeSelect}
                            onChangePage={handleChangePagination}
                            onSelectSort={handleSort}
                            page={page_num}
                            totalpage={totalpage}
                            columns={columns}
                            products={dataList}
                            cells={cellList}
                            editable={false}
                            onClickEdit={handleClickEdit}
                            onClickDelete={handleClickDelete}
                        />
                        <DistributeChart symbol={dutySymbol} distributeData={distributeData} />
                    </div>
                </div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
            </MuiPickersUtilsProvider>
        </div>
    )
}

Reception.prototype = {

}

export default Reception;