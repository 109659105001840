import React, { useEffect } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { withRouter } from 'react-router-dom';
import useStyles from './useStyles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AdminService from '../../services/api';
import Str from '../../constants/string';

const ResetPassword = (props) => {
    const classes = useStyles();
    const [token, setToken] = React.useState('');
    const [newPwd, setNewPwd] = React.useState('');
    const [confirmPwd, setConfirmPwd] = React.useState('');
    const [errorsNewPwd, setErrorsNewPwd] = React.useState('');
    const [errorsConfirmPwd, setErrorsConfirmPwd] = React.useState('');

    useEffect(() => {
        setToken(props.match.params.token);
    }, []);

    const handleChangeNewPwd = (event) => {
        event.preventDefault();
        setNewPwd(event.target.value);
        if (event.target.value.length > 0) {
            setErrorsNewPwd('');
        }
    }

    const handleChangeConfirmPwd = (event) => {
        event.preventDefault();
        setConfirmPwd(event.target.value);
        if (event.target.value.length > 0) {
            setErrorsConfirmPwd('');
        }
    }

    const handleClickButton = () => {
        let cnt = 0;
        if (newPwd.length === 0) { setErrorsNewPwd(Str.STR_INPUT_NEW_PASSWORD); cnt++; }
        if (confirmPwd.length === 0) { setErrorsConfirmPwd(Str.STR_INPUT_CONFIRM_PASSWORD); cnt++; }
        if (newPwd !== confirmPwd) { setErrorsConfirmPwd(Str.STR_NOT_EQUAL_PASSWORD); cnt++; }
        if (cnt === 0) {
            reset();
        }
    }

    const reset = () => {
        var data = {};
        data['token'] = token;
        data['password'] = newPwd;

        AdminService.resetPassword(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        ToastsStore.error(response.data.message);
                    } else {
                        ToastsStore.success(Str.STR_RESET_PASSWORD);
                    }
                },
                error => {
                    ToastsStore.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );
    }

    return (
        <div className={classes.divContainer}>
            <Grid container direction="column" justify="flex-start" className={classes.root}>
                <Grid item container justify="center">
                    <p className={classes.title}>Please input and reset your password.</p>
                </Grid>
                <Grid item container justify="center">
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                    <Grid xs={10} sm={7} md={4} item container direction="column" className={classes.body}>
                        <Grid item></Grid>
                        <Grid item container justify="center">
                            <p className={classes.boxTitle}><b>Reset your password</b></p>
                        </Grid>
                        <Grid item container className={classes.input}>
                            <Grid xs={1} item></Grid>
                            <Grid xs={10} item container direction="column" spacing={2}>
                                <Grid item><p className={classes.itemTitle}>New password</p></Grid>
                                <Grid item container direction="column">
                                    <TextField
                                        name="password"
                                        variant="outlined"
                                        fullWidth
                                        value={newPwd}
                                        onChange={handleChangeNewPwd}
                                        type="password"
                                        noValidate
                                    />
                                    {errorsNewPwd.length > 0 &&
                                        <span className={classes.error}>{errorsNewPwd}</span>}
                                </Grid>
                                <Grid item><p className={classes.itemTitle}>Confirm password</p></Grid>
                                <Grid item container direction="column">
                                    <TextField
                                        name="password"
                                        variant="outlined"
                                        fullWidth
                                        value={confirmPwd}
                                        onChange={handleChangeConfirmPwd}
                                        type="password"
                                        noValidate
                                    />
                                    {errorsConfirmPwd.length > 0 &&
                                        <span className={classes.error}>{errorsConfirmPwd}</span>}
                                </Grid>
                            </Grid>
                            <Grid xs={1} item></Grid>
                        </Grid>
                        <Grid item container justify="center">
                            <Button className={classes.button1} onClick={handleClickButton} id="reset">
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} sm={2} md={4}></Grid>
                </Grid>
            </Grid>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        </div>
    );
};
export default withRouter(ResetPassword);
