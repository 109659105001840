const str = {
    STR_CALL_END: "Call End",
    STR_CALL_LATER: "Call Later",
    STR_CALL_TYPE: "CallType",
    STR_RECEPTION_CALL_STATUS: "ReceptionCallStatus",
    STR_CALL_DURATION_TIME: "CallDurationTime",
    STR_EMPUTY: "",
    STR_NORMAL: "General Help",
    STR_NEW_PATIENT: "New Patient",
    STR_NO_APPOINTMENT: "No Appointment",
    STR_SELF_CHECK: "Self Check-in",
    STR_LOCAL_USER: "lucas_admin_username",
    STR_LOCAL_PWD: "lucas_admin_password",
    STR_LOCAL_IS_REMEMBER: "lucas_admin_is_remember",
    STR_OFF_LINE: "Offline",
    STR_AWAY: "Away",
    STR_ON_LINE: "Online",
    STR_ON_CALL: "On Call",
    STR_ADD_CLIENT: "Add Client",
    STR_ADD_USER: "╋ Add User",
    STR_LOGOUT: "Logout",
    STR_NO_DATA_FOUND: "No data found",
    STR_NO_PERMISSION: "No Permission",
    STR_NOT_CONNECT_SERVER: "Can't connect to the Server!",
    STR_ADDED_NEW_CLIENT: "Added new client successfully!",
    STR_UPDATED_CLIENT: "Updated a client successfully!",
    STR_DELETED_CLIENT: "Deleted a client successfully!",
    STR_ADDED_NEW_USER: "Added new user successfully!",
    STR_UPDATED_USER: "Updated a user successfully!",
    STR_DELETED_USER: "Deleted a user successfully!",
    STR_SEND_MESSAGE: "Send message successfully!",
    STR_RESET_PASSWORD: "Reset password successfully!",
    STR_UPDATED_ALL_USER: "Updated all user information successfully!",
    STR_IMAGE_SIZE_LOW_5M: "Image size should be low than 5 MB.",
    STR_IMAGE_FORMAT_NOT_CORRECT: "Image format is not correct.",
    STR_FILE_FORMAT_NOT_CORRECT: "File format is not correct.",
    STR_ASC: "ASC",
    STR_DESC: "DESC",
    STR_INPUT_USERNAME: "Please enter your username",
    STR_INPUT_PASSWORD: "Please enter your password",
    STR_INPUT_EMAIL: "Please enter your email",
    STR_INPUT_NEW_PASSWORD: "Please enter your new password",
    STR_INPUT_CONFIRM_PASSWORD: "Please enter your confirm password",
    STR_NOT_EQUAL_PASSWORD: "Please confirm new password",
    STR_USER_REACHED_LIMIT: "Sorry, you have reached the limit for receptionists.",
    STR_LOCATION_REACHED_LIMIT: "Sorry, you have reached the limit for locations.",
    STR_EMAIL_INVALID: "Email is not valid!",
    CHANGE_PASSWORD:'Change Password'
}

module.exports = str