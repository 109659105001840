import React, { useEffect ,useState} from 'react';
import MyButton from '../../components/MyButton';
import { AddTeamMemberStyles as useStyles } from './useStyles';
import AdminService from '../../services/api';
import { ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import {Grid,TextField, OutlinedInput ,FormControl,InputAdornment,IconButton} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const UpdatePassword = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [currentPass,setCurrentPass]=useState('')
    const [password,setPassword]=useState('')
    const [confPassword,setConfPassword]=useState('')
    const [errorsPassword, setErrorPassword] = React.useState('');
    const [errorsConfirmPassword, setErrorConfirmPassword] = React.useState('');
    const [errorsCurrentPassword, setErrorCurrentPassword] = React.useState('');
    const [showCurrentPass,setShowCurrentPass]=React.useState(false)
    const [showPassword,setShowPassword]=React.useState(false)
    const [showConfirmPassword,setShowConfirmPassword]=React.useState(false)
    useEffect(() => {

    }, []);

    const handleClose = () => {
        props.onCancel();
    };


    const handleChangeCurrenctPass=(e)=>{
        setCurrentPass(e.target.value)
    }
    const handleChangePassword=(e)=>{
        setPassword(e.target.value)
    }
    const handleChangeConfPassword=(e)=>{
        setConfPassword(e.target.value)
    }
    const updatePasword=async()=>{
        setErrorCurrentPassword('')
        setErrorPassword('')
        setErrorConfirmPassword('')
        if(!currentPass) return setErrorCurrentPassword("Please enter your current password.")
        if(!password) return setErrorPassword("Please enter new password.")
        if(password !==confPassword) return setErrorConfirmPassword('password and confirm password is not match.')
        const data={
            currentPassword:currentPass,
            newPassword:password,
            confNewPassword:confPassword
        }
        var res=await AdminService.updatePasswordClient(data)
        if(res.data.code==0) ToastsStore.success(res.data.message)
        else ToastsStore.error(res.data.message)
        handleClose()
    }
    const handleClickShowCurrentPassword=()=>{ setShowCurrentPass(true)}
    const handleMouseDownCurrentPassword=()=>{setShowCurrentPass(false) }

    const handleClickShowPassword=()=>{ setShowPassword(true)}
    const handleMouseDownPassword=()=>{setShowPassword(false) }

    const handleClickShowConfirmPassword=()=>{ setShowConfirmPassword(true)}
    const handleMouseDownConfirmPassword=()=>{setShowConfirmPassword(false) }
    return (
        <div>
            <div className={classes.paper} sm={12}>
                <Grid container spacing={2} >

                    <Grid item container justify="space-between" alignItems="center">
                        <Grid xs={3} item container><p className={classes.title}>Current Password</p></Grid>
                        <Grid xs={9} item container>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showCurrentPass ? 'text' : 'password'}
                            value={currentPass}
                            onChange={handleChangeCurrenctPass}
                            fullWidth
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCurrentPassword}
                                onMouseDown={handleMouseDownCurrentPassword}
                                edge="end"
                                >
                                {showCurrentPass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                            labelWidth={70}
                        />
                        {errorsCurrentPassword.length > 0 &&
                            <span className={classes.error}>{errorsCurrentPassword}</span>}
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between" alignItems="center">
                        <Grid xs={3} item container><p className={classes.title}>New Password</p></Grid>
                        <Grid xs={9} item container>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handleChangePassword}
                                fullWidth
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                labelWidth={70}
                            />
                            {errorsPassword.length > 0 &&
                                <span className={classes.error}>{errorsPassword}</span>}
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between" alignItems="center">
                        <Grid xs={3} item container><p className={classes.title}>Confirm Password</p></Grid>
                        <Grid xs={9} item container>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confPassword}
                                onChange={handleChangeConfPassword}
                                fullWidth
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownConfirmPassword}
                                    edge="end"
                                    >
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                labelWidth={70}
                            />
                            {errorsConfirmPassword.length > 0 &&
                                <span className={classes.error}>{errorsConfirmPassword}</span>}
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.footer}>
                    <Grid container justify="space-between">
                        <MyButton name={"\u00a0\u00a0\u00a0OK\u00a0\u00a0\u00a0"} color={"1"} onClick={updatePasword} />
                        <MyButton name={"Cancel"} bgColor="grey" onClick={handleClose} />
                    </Grid>
                </div>
            </div>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        </div>
        
    );
};

export default withRouter(UpdatePassword);
