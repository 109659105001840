import axios from 'axios';
import authHeader from './authHeader';
const API_URL = process.env.REACT_APP_API_URL;
class AdminService {
  //sign up
  signupAdmin(data) {
    return apiCall('auth/signupAdmin', data, { headers: authHeader() });
  }
  //login
  loginAdmin(data) {
    return apiCall('auth/loginAdmin', data, { headers: authHeader() });
  }
  //Login client
  loginClient(data) {
    return apiCall('auth/loginClient', data, { headers: authHeader() });
  }
  //sign up
  signupClient(data) {
    return apiCall('auth/signupClient', data, { headers: authHeader() });
  }

  //forgotPassword
  forgotPassword(data) {
    return apiCall('auth/forgotPassword', data, {});
  }
  //resetPassword
  resetPassword(data) {
    return apiCall('auth/resetPassword', data, {});
  }

  //update a Client Infomation without password
  updateClientInfomation(data) {
    return apiCall('admin/updateClientInfomation', data, { headers: authHeader() });
  }
  //update a Client Infomation with password
  updateClientInfomationPWD(data) {
    return apiCall('admin/updateClientInfomationPWD', data, { headers: authHeader() });
  }
  //delete a Client Infomation
  deleteClientInfomation(data) {
    return apiCall('admin/deleteClientInfomation', data, { headers: authHeader() });
  }
  //get All Client Infomation
  getAllClientInfomation(data) {
    return apiCall('admin/getAllClientInfomation', data, { headers: authHeader() });
  }
  //get Client Infomation with condition
  getClientInfomation(data) {
    return apiCall('admin/getClientInfomation', data, { headers: authHeader() });
  }
  //get a Client Infomation with id of a client
  getOneClientInfomation(data) {
    return apiCall('admin/getOneClientInfomation', data, { headers: authHeader() });
  }

  //get all user(locations and receptionist) info of a room
  getNumberofUsers(data) {
    return apiCall('admin/getNumberofUsers', data, { headers: authHeader() });
  }

  //get all user(locations and receptionist) info of a room
  getAllUserInfomation(data) {
    return apiCall('admin/getAllUserInfomation', data, { headers: authHeader() });
  }

  //signup for users(location and reception)
  signup(data) {
    return apiCall('auth/signup', data, { headers: authHeader() });
  }

  //update user(locations and receptionist) information of a room without password.
  updateUserInformation(data) {
    return apiCall('admin/updateUserInformation', data, { headers: authHeader() });
  }

  //delete a user(locations and receptionist).
  deleteUserInfomation(data) {
    return apiCall('admin/deleteUserInfomation', data, { headers: authHeader() });
  }

  //update db when User Leave
  userLeave(data) {
    return apiCall('admin/userLeave', data, { headers: authHeader() });
  }
  //get available room info
  getRoomInfo(data) {
    return apiCall('admin/roomInfo', data, {});
  }

  //get all user info of a room
  getAllUserInfo(data) {
    return apiCall('admin/allUserInfo', data, {});
  }

  //get type info from username
  getInfoFromUsername(data) {
    return apiCall('admin/getInfoFromUsername', data, {});
  }

  //update user status
  updateUserStatus(data) {
    return apiCall('admin/updateUserStatus', data, {});
  }

  //update own camera id
  updateSelfPartId(data) {
    return apiCall('admin/updateSelfPartId', data, {});
  }

  //update other camera id
  updateOtherPartId(data) {
    return apiCall('admin/updateOtherPartId', data, {});
  }

  //update holding camera id
  updateHoldPartId(data) {
    return apiCall('admin/updateHoldPartId', data, {});
  }

  //end current call.
  currentCallEnd(data) {
    return apiCall('admin/currentCallEnd', data, {});
  }

  //get unloged location list
  getUnlogedLocationList(data) {
    return apiCall('admin/getUnlogedLocationList', data, { headers: authHeader() });
  }

  //get Logined UserList
  getUserList(data) {
    return apiCall('admin/getUserList', data, { headers: authHeader() });
  }

  //get a number of users from db for admin pannel
  getUserInfomation(data) {
    return apiCall('admin/getUserInfomation', data, { headers: authHeader() });
  }

  //join User And Location
  joinUserAndLocation(data) {
    return apiCall('admin/joinUserAndLocation', data, { headers: authHeader() });
  }

  //Reception
  getAllReceptionList(data) {
    return apiCall('admin/getAllReceptionList', data, { headers: authHeader() });
  }
  getReceptionLogList(data) {
    return apiCall('admin/getReceptionLogList', data, { headers: authHeader() });
  }

  //Location
  getAllLocationList(data) {
    return apiCall('admin/getAllLocationList', data, { headers: authHeader() });
  }
  getLocationLogList(data) {
    return apiCall('admin/getLocationLogList', data, { headers: authHeader() });
  }
  getPayments(data) {
    return apiCall('admin/getPayments', data, { headers: authHeader() });
  }
  createNewVersion(data){
    return apiCall('admin/version/create',data,{headers:authHeader()})
  }
  getAllVersions(data){
    return apiCall('admin/version',data,{headers:authHeader()})
  }
  deleteVersion(data){
    return apiCall('admin/version/delete',data,{headers:authHeader()})
  }
  editVersion(data){
    return apiCall('admin/version/edit',data,{headers:authHeader()})
  }
  updatePasswordClient(data){
    return apiCall('admin/changePasswordClient',data,{headers:authHeader()})
  }
}
async function apiCall(url,data,options){
  return new Promise(async (resolve, reject)=>{
    try {
        var res=await axios.post(API_URL+url,data,options)
        if(options && options.headers && options.headers.Authorization){
          if(res.data.code===401){
            localStorage.clear()
            window.location.replace('/')
            return reject()
          }
        }
        return resolve(res)
    } catch (error) {
      return reject(error)
    }
  })
}

export default new AdminService();