import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog ,DialogActions,DialogContent,FormControlLabel,DialogTitle,
     TextField,Checkbox,FormControl,InputLabel,Select, MenuItem  ,
     TextareaAutosize ,FormHelperText,LinearProgress
    } from '@material-ui/core';
import Str from '../../constants/string';
import AdminService from '../../services/api';
import {ToastsStore } from 'react-toasts';
import MyButton from '../../components/MyButton';
import { useEffect } from 'react';
const listType=['Alpha','Beta','Stable']
const AddVersionControl= (props) => {
    const [version,setVersion]=useState('1.0.01')
    const [type,setType]=useState('Beta')
    const [app,setApp]=useState('')
    const [isActive,setIsActive]=useState(false)
    const [desc,setDesc]=useState('')
    const [validateMsgVersion,setValidateVersion]=useState('')
    const [validateMsgApp,setValidateApp]=useState('')
    const [uploading,setUploading]=useState(false)
    useEffect(()=>{
        setValidateApp('')
        setValidateVersion('')
        setUploading(false)
    },[props.open])

    const handleChangeVersion=(e)=>{
        setVersion(e.target.value)
    }
    const handleChangeType=(e)=>{
        setType(e.target.value)
    }
    const handleChangeFile=(e)=>{
        var file= e.target.files && e.target.files[0]
        var {name,type}=file
        let format=name && name.split('.').pop()
        if(format !=='exe' || !type || !type.includes('application')) return setValidateApp(Str.STR_FILE_FORMAT_NOT_CORRECT)
        setValidateApp('')
        setApp(file)
    }
    const handleChangeIsActive=(e)=>{
        setIsActive(e.target.checked)
    }
    const handleChangeDesc=(e)=>{
        setDesc(e.target.value)
 }
    const handleSubmit=async()=>{
        setValidateApp('')
        setValidateVersion('')
        if(!version) return setValidateVersion('This Field Is Required')
        if(!app || !app.type) return setValidateApp('This Field Is Required')
        var form=new FormData()
        form.set('type',type)
        form.set('version',version)
        form.set('app',app)
        form.set('isActive',isActive)
        form.set('description',desc)
        setUploading(true)
        var res=await AdminService.createNewVersion(form)
        setUploading(false)
        if(!res || !res.data || res.data.code!==0)  {
            return ToastsStore.error(res.data.message || 'Something Is Wrong, Please Try Again.');
        }
        ToastsStore.success(res && res.data.message);
        setValidateApp('')
        setValidateVersion('')
        setDesc('')
        setIsActive(false)
        setType('Beta')
        setVersion('0.0.0')
        setApp('')
        props.handleClose()
        props.onAdd()
    }
    return (
            <Dialog open={props.open} >
                <DialogTitle>New Version</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="version"
                            label="Version"
                            type="string"
                            fullWidth
                            variant="standard"
                            value={version}
                            onChange={handleChangeVersion}
                            required
                            />
                        <FormHelperText style={{color:"red"}}>{validateMsgVersion}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            value={type}
                            label="Type"
                            required
                            onChange={handleChangeType}
                        >
                            {
                                listType.map((t)=>{
                                    return <MenuItem value={t}>{t}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                            checked={isActive} 
                            onChange={handleChangeIsActive} 
                            />
                        } 
                        label="Active" 
                    />
                    <FormControl fullWidth>
                        <TextareaAutosize
                            
                            onChange={handleChangeDesc} 
                            value={desc}
                            minRows={4}
                            placeholder="Description"
                        />
                    </FormControl>
                    <FormControl fullWidth>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="file"
                            label="app"
                            type="file"
                            variant="standard"
                            onChange={handleChangeFile}
                            required
                            />
                        <FormHelperText style={{color:"red"}}>{validateMsgApp}</FormHelperText>

                    </FormControl>
                    
                </DialogContent>
                <DialogActions>
                    <MyButton name={"Submit"} color={"1"} onClick={handleSubmit} disabled={uploading} />
                    <MyButton name={"Cancel"} bgColor="grey" onClick={props.handleClose} disabled={uploading} />
                </DialogActions>
                {
                    uploading? 
                    <LinearProgress  />
                    :""
                }

            </Dialog>
    );
};

export default withRouter(AddVersionControl);
